import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./index.css";
import LandingPage from "./Pages/LandingPage";
import Features from "./Pages/features";
import ContactUs from "./Pages/contact-us";
import Services from "./Pages/services.js";
import * as serviceWorker from "./serviceWorker";

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route exact path="/" component={LandingPage} />
      <Route path="/features" component={Features} />
      <Route path="/contact-us" component={ContactUs} />
      <Route path="/services" component={Services} />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React, { Component } from "react";
import {
  Layout,
  Card,
  Button,
  Select,
  Col,
  Row,
  Icon,
  Form,
  Input,
  Drawer,
  Slider,
  InputNumber,
} from "antd";
import { Link } from "react-router-dom";
import { Modal as SuccessModal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import logoWhite from "../static/images/v-logo-white.png";
import RoverImg from "../static/images/rover-image.png";
import SateliteImg from "../static/images/satelite-image.png";
import MoonImg from "../static/images/moon.png";
import CapsuleImg from "../static/images/capsule.png";
import SpaceshipImg from "../static/images/spaceship-image.png";
import HomeBack from "../static/images/home-back.png";
import HomeBackMobile from "../static/images/home-back-mobile.png";
import HomeImg from "../static/images/device-mockup.png";
import HomeImgMobile from "../static/images/device-mockup-mobile.png";
import Fly from "../static/images/fly.png";
import EllipseSmall from "../static/images/Ellipse-small.png";
import EllipseLarge from "../static/images/Ellipse-large.png";
import MobileImg from "../static/images/mobile-image.png";
import CodeImg from "../static/images/code-image.png";
import UXImg from "../static/images/ux-image.png";
import PCImg from "../static/images/pc-image.png";
import rates from "../static/images/rates.png";
import rapid from "../static/images/rapid.png";
import creative from "../static/images/creative.png";
import BackMobileImg from "../static/images/back-mobile.png";
import RocketImg from "../static/images/rocket-image.png";
import FooterImg from "../static/images/footer-image.png";
import FooterImgMobile from "../static/images/footer-image-mobile.png";
import expert from "../static/images/expert.png";
import Number1 from "../static/images/01.png";
import Number2 from "../static/images/02.png";
import Number3 from "../static/images/03.png";
import { useSpring, animated } from "react-spring";
import "./sales-screen.css";
import Fade from "react-reveal/Fade";
import Header from "../Pages/Header";
import styled from "styled-components";

const FormItem = Form.Item;
const { Option } = Select;
const ProjectCards = styled(Card)`
  background-color: transparent;
  margin-bottom: 3em;
  top: -0em;
  margin: auto;
  text-align: center;
  height: 18em;
  width: 22em;
  border-radius: 1em;
  box-shadow: none;
  border: 0px solid #2482db;
  @media (max-width: 1000px) {
  }
  @media (max-width: 770px) {
  }
`;
const StyledInputNumber = styled(InputNumber)`
  border-top: 0px solid #d9d9d9;
  border-left: 0px solid #d9d9d9;
  border-right: 0px solid #d9d9d9;
  border-bottom: 1px solid #158577;

  color: black;
  background-color: transparent;
  border: 0;

  outline: 0;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  -moz-appearance: textfield !important;
`;

const StyledInput = styled(Input)`
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  padding-left: 1.8em !important;
  font-variant: tabular-nums;
  list-style: none;
  -webkit-font-feature-settings: "tnum", "tnum";
  font-feature-settings: "tnum", "tnum";
  position: relative;
  display: inline-block;
  width: 100%;
  height: 55px !important;
  padding: 4px 11px;
  color: black;
  box-shadow: none;
  line-height: 1.5;
  background-color: white;
  background-image: none;
  border: 0px solid #19c1b388;
  border-radius: 17px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
`;

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some((field) => fieldsError[field]);
}
const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2];
const trans1 = (x, y) => `translate3d(${x / 10}px,${y / 10}px,0)`;
const trans2 = (x, y) => `translate3d(${x / 8 + 35}px,${y / 8 - 230}px,0)`;
const trans3 = (x, y) => `translate3d(${x / 6 - 250}px,${y / 6 - 200}px,0)`;
const trans4 = (x, y) => `translate3d(${x / 3.5}px,${y / 3.5}px,0)`;

function CardAn() {
  const [props, set] = useSpring(() => ({
    xy: [0, 0],
    config: { mass: 10, tension: 550, friction: 140 },
  }));
  return (
    <div
      class="container"
      onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })}
    >
      <animated.div style={{ transform: props.xy.interpolate(trans1) }}>
        <img
          className="home-image"
          src={HomeImg}
          style={{
            zIndex: 9,
          }}
        ></img>

        <img
          className="home-image-mobile"
          src={HomeImgMobile}
          style={{
            zIndex: 9,
          }}
        ></img>
      </animated.div>
    </div>
  );
}

class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      placement: "left",
      inputValue: 1,
      FormChange: false,
      visible: false,
      open: false,
      successModal: false,
    };
    this.changeValue = this.changeValue.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange = (e) => {
    this.setState({
      placement: e.target.value,
    });
  };
  onChange = (value) => {
    this.setState({
      inputValue: value,
    });
  };
  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.onSubmit(values);
      }
    });
  };
  changeValue = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  onOpenModal = () => {
    this.setState({ open: true });
  };
  onCloseModal = () => {
    this.setState({ open: false });
  };
  handleCloseSuccessModal = () => {
    this.setState({ successModal: false });
    window.location.reload(false);
  };
  onClose = () => {
    this.setState({
      visible: false,
    });
  };
  onSubmit = (values) => {
    const MSG = {
      to: ["joseph@venturepoint.co.za"],
      from: "info@venturepoint.co.za",
      subject: "New contact request",
      text: " ",
      html: `
    
      <style type="text/css">  
      body, #bodyTable{background-color:white}
      #emailHeader{background-color:white}
      #emailBody{background-color:#FFFFFF; border:1px solid #CCCCCC;}
      #emailFooter{background-color:#E1E1E1;}      
</style>
<body bgcolor="#E1E1E1" leftmargin="0" marginwidth="0" topmargin="0" marginheight="0" offset="0">
<center style="background-color:#E1E1E1;">
<table border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="bodyTable" style="table-layout: fixed;max-width:100% !important;width: 100% !important;min-width: 100% !important;">
  <tr>
    <td align="center" valign="top" id="bodyCell">      
      <table bgcolor="#E1E1E1" border="0" cellpadding="0" cellspacing="0" width="500" id="emailHeader">
        <!-- HEADER ROW // -->
        <tr>
          <td align="center" valign="top">
            <!-- CENTERING TABLE // -->
            <table border="0" cellpadding="0" cellspacing="0" width="100%">
              <tr>
                <td align="center" valign="top">
                  <!-- FLEXIBLE CONTAINER // -->
                  <table border="0" cellpadding="10" cellspacing="0" width="500" class="flexibleContainer">
                    <tr>
                      <td valign="top" width="500" class="flexibleContainerCell">

                        <!-- CONTENT TABLE // -->
                        <table align="left" border="0" cellpadding="0" cellspacing="0" width="100%">
                          <tr>
                       
                            <td align="right" valign="middle" class="flexibleContainerBox">
                              <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width:100%;">
                                <tr>
                                  <td align="left" class="textContent">
                                    <!-- CONTENT // -->
                                   
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
            <!-- // CENTERING TABLE -->
          </td>
        </tr>
        <!-- // END -->
      </table>     
      <!-- EMAIL BODY // -->   
      <table bgcolor="#FFFFFF" border="0" cellpadding="0" cellspacing="0" width="500" id="emailBody">
        <!-- MODULE ROW // -->
        <tr>
          <td align="center" valign="top">
            <!-- CENTERING TABLE // -->
            <table border="0" cellpadding="0" cellspacing="0"   style="color:#FFFFFF;" bgcolor="#132440">
              <tr>
                <td align="center" valign="top">
                  <!-- FLEXIBLE CONTAINER // -->
                 
                  <table border="0" cellpadding="0" cellspacing="0" width="500"   class="flexibleContainer">
                    <tr>
                      <td align="center" valign="top" width="500" class="flexibleContainerCell">

                        <!-- CONTENT TABLE // -->
                      
                        <table border="0" cellpadding="0" cellspacing="0" width="100%" z-index="100" >
                               <td align="center" valign="top" width="500" class="flexibleContainerCell  ">   
                                <img alt="" src="https://i.ibb.co/M1P7DVd/w-delay-logo-white.png" width="300" height="80" style="display: block; width:155px; max-width: 155px; min-width: 155px;border-radius: 8px 8px 8px 8px; font-family: Lato, Helvetica, Arial, sans-serif; color: #ffffff; font-size: 18px;margin-top: 18px;" border="0">     <tr>
                            <td align="center" valign="top" class="textContent">
                              <h1 style="color:white;line-height:100%;font-family:Helvetica,Arial,sans-serif;font-size:35px;font-weight:normal;margin-bottom:20px;text-align:center;">Contact Request</h1>
                              <h2 style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:17px;margin-bottom:10px;color: white;line-height:135%;">You have received a new contact request from <br /> the Venturepoint website</h2>
               
                            </td>
                          </tr>
                        </table>
                        <!-- // CONTENT TABLE -->

                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
            <!-- // CENTERING TABLE -->
          </td>
        </tr>

        <!-- MODULE ROW // -->
        
        <tr mc:hideable>
          <td align="center" valign="top">
            <!-- CENTERING TABLE // -->
            <table border="0" cellpadding="0" cellspacing="0" width="100%" height="300" >
              <tr>
                <td align="center" valign="top">
                  <!-- FLEXIBLE CONTAINER // -->
                  <table border="0" cellpadding="0" cellspacing="0" width="300" class="flexibleContainer">
                    <tr>
                      <td valign="top" width="500" class="flexibleContainerCell">
                        <!-- CONTENT TABLE // -->
                        <table p align="left" border="0" cellpadding="0" cellspacing="0" width="120%">
                          <tr>
                            <td align="center" valign="top" class="flexibleContainerBox">
                               <table border="0" cellspacing="0" cellpadding="0" >
            
                        <tr style="font-family: sans-serif; font-size: 17px; letter-spacing: 1px; color:black">
                          <td class="role"><br /><br /><br /><br /><br />
                          <strong> Name </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.name}<br />
                               <strong> Email </strong>&nbsp;&nbsp;: &nbsp;&nbsp;${values.email}<br />
                               <strong> Number </strong>&nbsp;&nbsp; :                     &nbsp;&nbsp;${values.number}<br />
                               <strong> Company name </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.company}<br />
                               <strong> Message </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.message}<br />
                             <br /><br /><br />
                          </td>
                        </tr>
                        <table>
                            </td>
                          </tr>
                        </table>
                        <!-- // CONTENT TABLE -->

                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
            <!-- // CENTERING TABLE -->
          </td>
        </tr>
        <!-- // MODULE ROW -->


        <!-- MODULE ROW // -->
       
<table border="0" cellpadding="0" cellspacing="0" width="100%"  style="color:#FFFFFF;" bgcolor="white" style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:13px;margin-bottom:-80px;color: lightgray;line-height:135%;">
              <tr>
                <td align="center" valign="top">
                
                  <table border="0" cellpadding="0" cellspacing="0" width="500"   class="flexibleContainer">
                    <tr>
                      <td align="center" valign="top" width="500" class="flexibleContainerCell">

                    
                        <table border="0" cellpadding="30" cellspacing="0" width="100%">
                      
                    
                          <tr>
                            <td align="center" valign="top" class="textContent">
        
                              <h2 style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:13px;margin-bottom:0px;color: grey;line-height:135%;">2020 Venturepoint</h2>
               
                            </td>
                          </tr>
                        </table>
                        <!-- // CONTENT TABLE -->

                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
     `,
    };
    fetch("https://www.vcsappcloud.com/emailclient/sendmail", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify(MSG),
    }).then(async (res) => {
      if (!res.ok) {
        console.log("handle error");
      }
      console.log("handle success");
      this.setState({
        successModal: true,
        submitDisabled: true,
        name: "",
        email: "",
        number: "",
        company: "",

        message: "",
      });
    });
  };
  render() {
    const { getFieldDecorator, getFieldsError } = this.props.form;
    const { inputValue } = this.state;
    return (
      <React.Fragment>
        {" "}
        <Button
          className="burger-menu"
          style={{
            float: "right",
            top: "1.5em",
            right: "0.5em",
            zIndex: 99999999999,
            border: "none",
            boxShadow: "none",
          }}
          type="primary"
          onClick={this.showDrawer}
        >
          <Icon
            style={{ color: "white", fontSize: "28px" }}
            type="menu"
            onClick={this.showDrawer}
          ></Icon>
        </Button>
        <Drawer
          style={{ fontFamily: "nunito" }}
          title="Menu"
          placement={this.state.placement}
          onClose={this.onClose}
          visible={this.state.visible}
        >
          <div style={{}}>
            <a
              onClick={this.onClose}
              href="/"
              style={{
                color: "white",
                fontSize: "25px",
                fontWeight: 500,
                fontFamily: "nunito",
              }}
            >
              Home
            </a>
          </div>
          <br />
          <a href="#why-us" style={{}}>
            <a
              onClick={this.onClose}
              style={{
                top: "3em",
                color: "white",
                fontSize: "25px",
                fontWeight: 500,
                fontFamily: "nunito",
              }}
            >
              Why Us ?
            </a>
          </a>
          <br /> <br />
          <a
            onClick={this.onClose}
            style={{
              color: "white",
              fontSize: "25px",
              fontWeight: 500,
              fontFamily: "nunito",
            }}
            href="#contact-us"
          >
            Contact Us
          </a>{" "}
          <img
            src={RocketImg}
            style={{
              marginTop: "7em",
              zIndex: 9,
              width: "12em",
              right: 0,
              left: 20,
              bottom: 0,
              position: "absolute",
            }}
          ></img>
        </Drawer>{" "}
        <img
          className="home-back"
          src={HomeBack}
          style={{
            width: "100vw",
            height: "140vh",
            position: "absolute",
            maxHeight: "110em",
            minHeight: "80em",
          }}
        ></img>{" "}
        <img
          className="home-back-mobile"
          src={HomeBackMobile}
          style={{
            position: "absolute",
            width: "100vw",
            height: "110vh",
          }}
        ></img>{" "}
        <Header />
        <div
          id="home"
          className="home-div"
          style={{
            padding: "30px",
            minHeight: "65em",
            maxHeight: "110em",
            width: "100vw",
            height: "115vh",
          }}
        >
          {" "}
          <Row
            className="home-row"
            style={{
              maxWidth: "80em",
              bottom: "-8%",
              margin: "auto",
            }}
          >
            <Col sm={24} md={12}>
              {" "}
              <h1
                className="home-header"
                style={{
                  fontSize: "70px",
                  fontWeight: 700,
                  lineHeight: "0.5em",
                  marginTop: "20%",
                  color: "rgb(248, 248, 248)",
                  fontFamily: "nunito",
                }}
              >
                <Fade duration={1100} delay={200} left cascade>
                  We build
                  <br />
                  innovative
                  <br />
                  digital products
                </Fade>
              </h1>
              <a href="#contact-us">
                <Button
                  className="home-button"
                  style={{
                    height: "2.8em",
                    width: "13em",
                    fontSize: "20px",
                    fontWeight: 500,
                    borderRadius: "3em",
                    color: "white",
                    border: "none",
                    marginTop: "2em",
                    backgroundColor: "#E94347",
                    fontFamily: "nunito-regular",
                    textShadow: "none",
                    boxShadow: "none",
                  }}
                >
                  Start Your Project
                </Button>
              </a>
            </Col>
            <Col sm={24} md={12} style={{ zIndex: 1 }}>
              <img
                className="home-image"
                src={HomeImg}
                style={{
                  zIndex: 9,
                }}
              ></img>

              <img
                className="home-image-mobile"
                src={HomeImgMobile}
                style={{
                  zIndex: 9,
                }}
              ></img>
            </Col>{" "}
            <img
              className="ellipse-small"
              src={EllipseSmall}
              style={{
                position: "absolute",
                left: "50%",
                top: "-2.8em",
                zIndex: 0,
              }}
            ></img>
            <img
              className="ellipse-large"
              src={EllipseLarge}
              style={{
                position: "absolute",
                left: "50%",
                top: "-2.8em",
                zIndex: 0,
              }}
            ></img>
          </Row>
        </div>
        <Row
          id="what-we-do"
          className="what-we-do"
          style={{
            maxWidth: "90em",

            margin: "auto",
            marginTop: "3em",
          }}
        >
          <Col sm={24} md={24} style={{ textAlign: "center" }}>
            <h1
              style={{
                fontSize: "55px",
                lineHeight: "1em",
                marginTop: "0%",
                color: "000000",
              }}
            >
              {" "}
              <h1
                className="center-header"
                style={{
                  fontSize: "55px",
                  fontFamily: "nunito-regular",
                  lineHeight: "1em",

                  color: "000000",
                }}
              >
                {" "}
                <Fade duration={1100} delay={200} left cascade>
                  What we do
                </Fade>
              </h1>
            </h1>
            <p
              className="center-paragraph"
              style={{
                fontSize: "19px",
                paddingRight: "3em",
                paddingLeft: "3em",
                lineHeight: "1.1em",
                marginTop: "0.8em",
                fontFamily: "nunito-regular",
                color: "#5E5E5E",
              }}
            >
              We Design beautiful apps to help you succeed
            </p>
          </Col>
          <Col span={12}></Col>
        </Row>
        <Fade duration={2000} delay={200}>
          <Row
            style={{
              maxWidth: "100em",
              margin: "auto",
              marginTop: "4em",
            }}
          >
            <Col
              sm={24}
              md={12}
              lg={6}
              style={{ margin: "auto", textAlign: "center" }}
              className="what-we-boxes"
            >
              <h1
                className="cardHeader"
                style={{
                  width: "10em",
                  margin: "auto",
                  marginBottom: "0.5em",
                  color: "black",
                  fontSize: "30px",
                  borderLeft: "0px solid #19C1B2",
                  lineHeight: "35px",
                  fontFamily: "nunito-regular",
                }}
              >
                {" "}
                Mobile App <br />
                Development
              </h1>{" "}
              <ProjectCards
                className="cardItem"
                style={{ margin: "auto", textAlign: "center" }}
              >
                <img
                  className="cardImg"
                  src={MobileImg}
                  style={{
                    marginTop: "0.35em",
                    width: "5.3em",
                  }}
                ></img>{" "}
                <p
                  style={{
                    marginTop: "1.3em",
                    color: "#3A3A3A",
                    fontSize: "17px",
                    fontWeight: "500",
                    fontFamily: "nunito-regular",
                    textAlign: "center",
                  }}
                >
                  We transform your ideas into beautiful apps for Android &
                  Apple mobile devices
                </p>{" "}
              </ProjectCards>{" "}
            </Col>
            <Col
              className="what-we-boxes"
              sm={24}
              md={12}
              lg={6}
              style={{ margin: "auto", textAlign: "center" }}
            >
              <h1
                className="cardHeader"
                style={{
                  width: "10em",
                  margin: "auto",
                  marginBottom: "0.5em",
                  color: "black",
                  fontSize: "30px",
                  borderLeft: "0px solid #19C1B2",
                  lineHeight: "35px",
                  fontFamily: "nunito-regular",
                }}
              >
                Web App <br />
                Development
              </h1>{" "}
              <ProjectCards className="cardItem">
                <img
                  className="cardImg"
                  src={CodeImg}
                  style={{
                    marginTop: "1em",
                    width: "5em",
                  }}
                ></img>{" "}
                <p
                  style={{
                    fontFamily: "nunito-regular",
                    marginTop: "1.3em",
                    color: "#3A3A3A",
                    fontSize: "17px",
                    fontWeight: "500",

                    textAlign: "center",
                  }}
                >
                  We provide custom web app development services for any size of
                  idea
                </p>{" "}
              </ProjectCards>{" "}
            </Col>
            <Col
              className="what-we-boxes"
              sm={24}
              md={12}
              lg={6}
              style={{ margin: "auto", textAlign: "center" }}
            >
              {" "}
              <h1
                className="cardHeader"
                style={{
                  width: "10em",
                  margin: "auto",
                  marginBottom: "0.5em",
                  color: "black",
                  fontSize: "30px",
                  borderLeft: "0px solid #19C1B2",
                  lineHeight: "35px",
                  fontFamily: "nunito-regular",
                }}
              >
                UI/UX <br />
                Design
              </h1>{" "}
              <ProjectCards className="cardItem">
                <img
                  className="cardImg"
                  src={UXImg}
                  style={{
                    marginTop: "1em",
                    width: "5em",
                  }}
                ></img>{" "}
                <p
                  style={{
                    marginTop: "1.3em",
                    color: "#3A3A3A",
                    fontSize: "17px",
                    fontWeight: "500",
                    fontFamily: "nunito-regular",
                    textAlign: "center",
                  }}
                >
                  Let our UX experts make your idea not only look vibrant but
                  flow with ease
                </p>{" "}
              </ProjectCards>{" "}
            </Col>
            <Col
              className="what-we-boxes"
              sm={24}
              md={12}
              lg={6}
              style={{ margin: "auto", textAlign: "center" }}
            >
              {" "}
              <h1
                className="cardHeader"
                style={{
                  width: "10em",
                  margin: "auto",
                  marginBottom: "0.5em",
                  color: "black",
                  fontSize: "30px",
                  borderLeft: "0px solid #19C1B2",
                  lineHeight: "35px",
                  fontFamily: "nunito-regular",
                }}
              >
                Custom <br />
                Software
              </h1>{" "}
              <ProjectCards className="cardItem">
                <img
                  className="cardImg"
                  src={PCImg}
                  style={{
                    marginTop: "1em",
                    width: "5em",
                  }}
                ></img>{" "}
                <p
                  style={{
                    marginTop: "1.3em",
                    color: "#3A3A3A",
                    fontSize: "17px",
                    fontWeight: "500",
                    fontFamily: "nunito-regular",

                    textAlign: "center",
                  }}
                >
                  We develop custom software applications for enterprise, web,
                  desktop and mobile platforms
                </p>{" "}
              </ProjectCards>{" "}
            </Col>{" "}
          </Row>
        </Fade>
        <Row
          style={{
            textAlign: "center",
            maxWidth: "110em",
            margin: "auto",
            marginTop: "2em",
          }}
        >
          {" "}
          <Link
            to="./services"
            className="discover-button"
            style={{
              height: "2.8em",
              width: "16.5em",
              fontSize: "20px",
              borderRadius: "3em",
              color: "white",
              border: "0px solid #E94347",
              backgroundColor: "#E94347",
              textShadow: "none",
              boxShadow: "none",
              padding: "0.7em",
              paddingLeft: "2.8em",
              paddingRight: "2.8em",
              fontFamily: "nunito-regular",
            }}
            type="primary"
          >
            Discover our process
          </Link>
        </Row>{" "}
        <img
          className="moon-image"
          src={MoonImg}
          style={{
            position: "absolute",
          }}
        ></img>
        <Row
          id="why-us"
          style={{
            maxWidth: "90em",
            margin: "auto",
          }}
        >
          {" "}
          <Col sm={24} md={24} style={{ margin: "auto", textAlign: "center" }}>
            <h1
              className="center-header-why"
              style={{
                fontSize: "50px",
                fontFamily: "nunito-regular",
                lineHeight: "1em",
                marginTop: "2em",
                color: "000000",
              }}
            >
              <Fade duration={1000} delay={200} left cascade>
                Why our clients love us
              </Fade>
            </h1>{" "}
            <p
              className="center-paragraph-why"
              style={{
                fontSize: "19px",
                fontFamily: "nunito-regular",
                lineHeight: "1.1em",
                marginTop: "0.8em",

                color: "#5E5E5E",
              }}
            >
              We create the kind of apps that people talk about
            </p>
          </Col>
        </Row>{" "}
        <Row
          className="display-inline"
          style={{
            maxWidth: "85em",
            textAlign: "center",
            display: "flex",
            margin: "auto",
          }}
        >
          <Col sm={24} md={12} style={{ margin: "auto" }}>
            <Fade duration={1100} delay={200} left cascade>
              <img
                className="rates-image"
                src={RoverImg}
                style={{
                  marginTop: "4em",
                  width: "22em",
                }}
              ></img>
            </Fade>
          </Col>

          <Col
            className="center"
            sm={24}
            md={12}
            style={{ textAlign: "center" }}
          >
            {" "}
            <Fade duration={1100} delay={200} right>
              <h1
                className="center-subheader-deep"
                style={{
                  fontSize: "35px",
                  lineHeight: "1em",
                  marginTop: "3em",
                  fontFamily: "nunito-regular",
                  color: "000000",
                }}
              >
                Deep Tech Expertise
              </h1>
              <p
                className="center-sub-paragraph"
                style={{
                  fontSize: "1.1rem",
                  fontFamily: "nunito-regular",
                  lineHeight: "1.3em",
                  marginTop: "0.8em",
                  color: "#5E5E5E",
                }}
              >
                Our team consists of the most experienced
                <br /> mobile developers in the industry using the <br />
                latest technology
              </p>
            </Fade>
          </Col>
        </Row>{" "}
        <img
          className="capsule-image"
          src={CapsuleImg}
          style={{
            right: -30,
            position: "absolute",
          }}
        ></img>
        <Row
          className="display-inline"
          style={{
            maxWidth: "85em",
            textAlign: "center",
            display: "flex",
            margin: "auto",
            marginTop: "-1em",
          }}
        >
          {" "}
          <Col
            className="center"
            sm={24}
            md={12}
            style={{ textAlign: "center" }}
          >
            {" "}
            <Fade duration={1100} delay={200} left>
              <h1
                className="center-subheader-rapid"
                style={{
                  fontSize: "35px",
                  lineHeight: "1em",
                  marginTop: "3em",
                  fontFamily: "nunito-regular",
                  color: "000000",
                }}
              >
                Rapid and Trusted Delivery
              </h1>
              <p
                className="center-sub-paragraph"
                style={{
                  fontSize: "1.1rem",
                  fontFamily: "nunito-regular",
                  lineHeight: "1.3em",
                  marginTop: "0.8em",
                  color: "#5E5E5E",
                }}
              >
                Using agile methodology to keep you in the loop
                <br /> with streamlined and cost effective projects <br />
                designed to match your goals and budget
              </p>
            </Fade>
          </Col>
          <Col sm={24} md={12} style={{ margin: "auto" }}>
            <Fade duration={1100} delay={200} right>
              <img
                className="satelite-image"
                src={SateliteImg}
                style={{
                  marginTop: "1em",
                  width: "22em",
                }}
              ></img>
            </Fade>
          </Col>
        </Row>{" "}
        <Row
          className="display-inline"
          style={{
            maxWidth: "85em",
            textAlign: "center",
            display: "flex",
            margin: "auto",
            marginTop: "0em",
          }}
        >
          <Col sm={24} md={12} style={{ margin: "auto" }}>
            <Fade duration={1100} delay={200} left>
              <img
                className="space-image"
                src={SpaceshipImg}
                style={{
                  marginTop: "2em",
                  width: "20em",
                }}
              ></img>
            </Fade>
          </Col>

          <Col
            className="center"
            sm={24}
            md={12}
            style={{ textAlign: "center" }}
          >
            {" "}
            <Fade duration={1100} delay={200} right>
              <h1
                className="center-subheader-agile"
                style={{
                  fontSize: "35px",
                  lineHeight: "1em",
                  marginTop: "3em",
                  fontFamily: "nunito-regular",
                  color: "000000",
                }}
              >
                Agile Development
              </h1>
              <p
                className="center-sub-paragraph"
                style={{
                  fontSize: "1.1rem",
                  fontFamily: "nunito-regular",
                  lineHeight: "1.3em",
                  marginTop: "0.8em",
                  color: "#5E5E5E",
                }}
              >
                Our team of experts design solutions that work <br />
                across all platforms and devices. Offering competitive <br />
                rates for quality production and experienced
                <br /> professionals
              </p>
            </Fade>
          </Col>
        </Row>
        <Row
          style={{
            maxWidth: "90em",

            margin: "auto",
            marginTop: "8em",
            marginBottom: "7em",
            margin: "auto",
            textAlign: "center",
          }}
        >
          <Col span={12}></Col>
        </Row>
        <Row
          gutter={24}
          style={{
            margin: "auto",
          }}
        >
          <Row
            gutter={24}
            style={{
              maxWidth: "90em",
              margin: "auto",
            }}
          >
            <Col span={17}>
              <Card
                className="message-card"
                style={{
                  margin: "auto",
                  borderRadius: "3.5em",
                  width: "100%",
                  padding: "0px",
                  maxWidth: "60em",
                  backgroundColor: "transparent",
                  marginBottom: "-3.5em",
                  paddingTop: "0em",
                  boxShadow: "none",
                  zIndex: 9,
                }}
                bordered={false}
              >
                <h1
                  className="contact-div"
                  style={{
                    fontSize: "55px",
                    lineHeight: "1em",
                    marginBottom: "1.5em",

                    marginLeft: "1em",
                    color: "white",
                  }}
                >
                  {" "}
                  <h1
                    className="center-header-contact"
                    style={{
                      fontSize: "55px",
                      fontFamily: "nunito-regular",
                      lineHeight: "1em",
                      color: "white",
                    }}
                  >
                    {" "}
                    Get in touch
                  </h1>
                  <p
                    className="center-paragraph-why1"
                    style={{
                      fontSize: "19px",
                      fontFamily: "nunito-regular",
                      lineHeight: "0em",
                      marginTop: "0.8em",

                      color: "white",
                    }}
                  >
                    Your adventure starts here
                  </p>
                </h1>
                <Form onSubmit={this.handleSubmit}>
                  <Row gutter={24} style={{ margin: "auto" }}>
                    <Col sm={24} md={24}>
                      <FormItem
                        className="form-item-style"
                        style={{
                          marginLeft: "3em",
                          marginRight: "3em",
                        }}
                      >
                        {getFieldDecorator("name", {
                          rules: [
                            { required: true, message: "Full name required" },
                          ],
                        })(
                          <StyledInput
                            style={{
                              width: "100%",

                              minWidth: "10.5em",
                            }}
                            id="standard-basic"
                            placeholder="Full name"
                          />
                        )}{" "}
                      </FormItem>
                    </Col>{" "}
                    <Col sm={24} md={24}>
                      <FormItem
                        className="form-item-style"
                        style={{
                          marginTop: "0em",
                          marginLeft: "3em",
                          marginRight: "3em",
                        }}
                      >
                        {getFieldDecorator("company", {
                          rules: [
                            {
                              message: "Company required",
                            },
                          ],
                        })(
                          <StyledInput
                            style={{
                              width: "100%",

                              minWidth: "10.5em",
                            }}
                            id="standard-basic"
                            placeholder="Company name (Optional)"
                          />
                        )}{" "}
                      </FormItem>
                    </Col>
                  </Row>{" "}
                  <Row
                    gutter={24}
                    style={{ margin: "auto", marginTop: "0em" }}
                    className="mail-item-margin-style"
                  >
                    <Col sm={24} md={12}>
                      <FormItem
                        style={{ marginLeft: "3em" }}
                        className="form-item-style"
                      >
                        {getFieldDecorator("email", {
                          rules: [
                            {
                              required: true,
                              message: "Email address required",
                            },
                          ],
                        })(
                          <StyledInput
                            style={{
                              width: "100%",
                              minWidth: "10.5em",
                            }}
                            id="standard-basic"
                            placeholder="Email address"
                          />
                        )}{" "}
                      </FormItem>{" "}
                    </Col>
                    <Col sm={24} md={12}>
                      <FormItem
                        className="form-item-style-number"
                        style={{ marginRight: "3em" }}
                      >
                        {getFieldDecorator("number", {
                          rules: [{}, ,],
                        })(
                          <StyledInput
                            style={{
                              width: "100%",
                              minWidth: "10.5em",
                            }}
                            id="standard-basic"
                            placeholder="Phone number (Optional)"
                          />
                        )}{" "}
                      </FormItem>{" "}
                    </Col>
                  </Row>
                  {/*  <Row
                  gutter={24}
                  style={{
                    margin: "auto",
                    marginTop: "0em",
                    maxWidth: "50em",

                    marginRight: "0%",
                  }}
                  className="form-item-margin-style"
                >
                  <Col
                    style={{
                      marginRight: "0%",
                      width: "100%",
                      marginBottom: "0em",
                    }}
                    md={24}
                  >
                    {" "}
                    <p
                      className="input-budget-text"
                      style={{
                        fontSize: "15px",
                        fontFamily: "nunito",
                        marginTop: "0em",
                        fontWeight: 500,
                        color: "white",
                      }}
                    >
                      Your budget is up to &nbsp;
                      <span style={{ color: "white", fontWeight: 400 }}>
                        R
                      </span>{" "}
                      <StyledInputNumber
                        className="input-budget-number"
                        disabled
                        min={1}
                        max={500000}
                        style={{
                          fontSize: "15px",
                          color: "white",
                          position: "absolute",
                          marginLeft: "-0.3em",
                          top: "-0.25em",
                        }}
                        value={inputValue}
                        onChange={this.onChange}
                      />
                    </p>
                    <FormItem>
                      {getFieldDecorator("slider", {
                        rules: [
                          {
                            required: false,
                          },
                        ],
                      })(
                        <Slider
                          id="standard-basic"
                          style={{}}
                          className="input-budget"
                          min={1}
                          max={500000}
                          marks={{
                            1: "1",
                            100000: "100k",
                            200000: "200k",
                            300000: "300k",
                            400000: "400k",
                            500000: "500k",
                          }}
                          onChange={this.onChange}
                          setFieldsValue={
                            typeof inputValue === "number" ? inputValue : 0
                          }
                        />
                      )}{" "}
                    </FormItem>{" "}
                  </Col>
                        </Row> */}
                  <Row
                    gutter={24}
                    style={{ margin: "auto", marginTop: "0em" }}
                    className="form-item-margin-style"
                  >
                    {" "}
                    <Col sm={24} md={24} style={{}}>
                      <FormItem
                        className="form-item-style"
                        style={{ marginLeft: "3em", marginRight: "3em" }}
                      >
                        {getFieldDecorator("message", {
                          rules: [
                            {
                              required: false,
                            },
                          ],
                        })(
                          <StyledInput
                            style={{ width: "100%" }}
                            id="standard-basic"
                            placeholder="About your project"
                          />
                        )}
                      </FormItem>
                    </Col>{" "}
                  </Row>
                  {/*<Row
                  className="form-item-facebook-style"
                  style={{
                    margin: "auto",
                    textAlign: "right",
                    marginRight: "3.7em",
                  }}
                >
                  {" "}
                  <Icon
                    type="facebook"
                    theme="filled"
                    style={{ fontSize: 27, color: "#00D326" }}
                  />
                </Row> */}
                  <Row
                    className="send-message-button"
                    style={{
                      margin: "auto",

                      textAlign: "right",
                      marginRight: "3.8em",
                    }}
                  >
                    <button
                      disabled={hasErrors(getFieldsError())}
                      htmlType="submit"
                      style={{
                        border: "none",
                        fontSize: "20px",
                        border: "0px solid #00D326",
                        marginTop: "1em",
                        background: "#E94347",
                        color: "white",
                        borderRadius: "2em",
                        width: "45%",
                        minWidth: "10em",
                        height: "2.9em",
                        paddingTop: "0px",
                        fontFamily: "nunito-regular",
                        textShadow: "none",
                        boxShadow: "none",
                      }}
                      id="loginButton"
                      className="form-item-button-style"
                      type="primary"
                    >
                      Send Message
                    </button>
                  </Row>
                </Form>{" "}
              </Card>{" "}
            </Col>
            <Col
              className="contact-hidden"
              span={7}
              style={{
                zIndex: 9,
                borderLeft: "1px solid grey",
                marginTop: "15.4em",
              }}
            >
              <p
                style={{
                  fontFamily: "nunito-regular",
                  fontSize: "18px",
                  color: "lightgrey",
                }}
              >
                Contact
              </p>

              <p
                style={{
                  textAlign: "left",

                  fontFamily: "nunito-regular",
                  fontSize: "14px",
                  color: "white",
                }}
              >
                Joseph Schorn (Director)
                <br />
                084 568 7637
              </p>
              <p
                style={{
                  fontFamily: "nunito-regular",
                  fontSize: "18px",
                  color: "lightgrey",
                }}
              >
                Address
              </p>

              <p
                style={{
                  textAlign: "left",

                  float: "left",
                  fontFamily: "nunito-regular",
                  fontSize: "14px",
                  color: "white",
                }}
              >
                11-04 Phoenix View Estate
                <br />
                Midrand
                <br />
                1648
              </p>
            </Col>
          </Row>
          <Row
            id="contact-us"
            style={{
              height: "45em",
              width: "100vw",
              position: "absolute",
              bottom: 0,
            }}
          >
            {" "}
            <img
              className="footer-image"
              src={FooterImg}
              style={{
                width: "100vw",
              }}
            ></img>
            <img
              className="footer-image-mobile"
              src={FooterImgMobile}
              style={{
                width: "100vw",
              }}
            ></img>
          </Row>
          <SuccessModal
            open={this.state.successModal}
            onClose={this.handleCloseSuccessModal}
            center
          >
            <img src={logoWhite} alt="Logo" style={{ width: "6em" }} />

            <h3
              style={{ marginTop: "1em", color: "white", textAlign: "center" }}
            >
              Thank you for submitting your message. <br />
              We'll get in touch as soon as possible.
            </h3>
          </SuccessModal>
          <Row
            style={{
              position: "absolute",
              textAlign: "center",
              bottom: "-10em",
              right: 0,
              left: 0,
              color: "lightgrey",
            }}
          >
            <p style={{ fontSize: "13px" }}>
              © 2020 Venturepoint - All rights reserved
            </p>
          </Row>
        </Row>
      </React.Fragment>
    );
  }
}
const WrappedHorizontalLoginForm = Form.create({ name: "horizontal_login" })(
  HomePage
);
export default WrappedHorizontalLoginForm;

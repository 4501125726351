import React, { Component } from "react";
import {
  Layout,
  Icon,
  Button,
  Col,
  Row,
  Checkbox,
  Form,
  Input,
  Tooltip,
  Cascader,
  Select,
  AutoComplete,
} from "antd";
import logo from "../static/images/vpos-logo.png";
import support from "../static/images/support.png";
import logoWhite from "../static/images/vpos-logo-white.png";
import Fade from "react-reveal/Fade";
import wave from "../static/images/wave.png";
import apple from "../static/images/apple.png";
import google from "../static/images/google.png";
import "./sales-screen.css";
import { Link } from "react-router-dom";
import validator from "validator";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
const sgMail = require("@sendgrid/mail");
const { TextArea } = Input;
const { Header, Content, Footer } = Layout;
const { Option } = Select;
function onChange(e) {
  console.log(`checked = ${e.target.checked}`);
}
const TopHeader = () => {
  return (
    <Header className="header-home">
      <img src={logo} alt="Logo" className="verkoopnou-logo" />

      <div></div>
      <div className="header-links">
        <Link to="/">
          <a
            style={{
              color: "black",
              fontSize: "17px",
              fontWeight: 500,
              paddingLeft: "20%",
            }}
          >
            Home
          </a>{" "}
        </Link>
        <a href="/features">
          <a
            style={{
              color: "black",
              fontSize: "17px",
              fontWeight: 500,
              paddingLeft: "8%",
            }}
          >
            Features
          </a>
        </a>
        <a
          style={{
            color: "black",
            fontSize: "17px",
            fontWeight: 500,
            paddingLeft: "8%",
          }}
          href="/contact-us"
        >
          Contact
        </a>{" "}
        <a href="https://www.devvcsappcloud.com/verkoopnou/signin">
          <Button
            style={{
              fontSize: "17px",
              fontWeight: 500,
              marginLeft: "18%",
            }}
            id="headerButton"
            className="features-header-buttons"
            type="primary"
          >
            Learn More
          </Button>
        </a>
      </div>
    </Header>
  );
};
const BottomFooter = () => {
  return (
    <Footer className="bottom-footer">
      <div
        style={{
          backgroundColor: "#171717",
          width: "110%",
          marginLeft: "-3em",
        }}
      >
        <img src={logoWhite} alt="Logo" className="footer-logo" />
        <img src={apple} alt="Logo" className="footer-logo-apple" />
        <img src={google} alt="Logo" className="footer-logo-google" />

        <div className="footer-links-div">
          <h1 style={{ color: "white", fontSize: "20px" }}>Company</h1>
          <Link to="/" style={{ color: "white" }}>
            Home
          </Link>
          <br />
          <a>
            <a style={{ color: " white" }}>Terms</a>
          </a>
          <br />
          <Link to="">
            <a
              href="https://www.devvcsappcloud.com/verkoopnou/signup"
              style={{ color: " white" }}
            >
              Privacy Policy
            </a>{" "}
          </Link>
          <br />{" "}
          <Link>
            <a style={{ color: " white" }}>Cookie Policy</a>
          </Link>
          <br />
        </div>
        <div className="footer-text">
          <h1 style={{ color: "white", fontSize: "20px" }}>Contact</h1>
          <p style={{ color: " white" }}>
            11-04 Phoenix View Estate
            <br />
            Midrand
            <br /> South Africa
            <br />
            www.venturepoint.co.za
          </p>
          <Icon
            type="facebook"
            theme="filled"
            style={{
              paddingRight: "0.5em",
              fontSize: "2em",
              color: " white",
            }}
            url="https://www.facebook.com/"
          />
          <Icon
            type="linkedin"
            theme="filled"
            style={{ paddingRight: "0.5em", fontSize: "2em", color: " white" }}
          />
        </div>
        <div style={{ textAlign: "left" }} className="footer-watermark">
          <p style={{ color: "white" }}>
            Copyright © 2020.{" "}
            <Link to="https://www.venturepoint.co.za">
              <a style={{ color: "white" }}>
                {" "}
                Venturepoint Cosnulting Services
              </a>
            </Link>
            All rights reserved.
          </p>
        </div>
      </div>
    </Footer>
  );
};
function handleChange(value) {
  console.log(`selected ${value}`);
}
class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      phonenumber: "",
      company: "",
      message: "",
      formValidity: false,
      submitDisabled: false,
      formErrors: {
        name: "Please enter your name",
        email: "Please enter a valid Email",
        phonenumber:
          "Please enter a valid phone number containing 10 characters",
      },
    };

    const SENDGRID_API_KEY =
      "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";
    sgMail.setApiKey(SENDGRID_API_KEY);

    this.changeValue = this.changeValue.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }
  componentDidMount() {
    document.body.scrollTop = 0;
  }
  onfocus = function () {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  };
  changeValue = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  handleCloseSuccessModal = () => {
    this.setState({ successModal: false });
  };
  onSubmit = (event) => {
    event.preventDefault();

    let formValidity = true;
    let errorType = undefined;
    let errorType1 = undefined;
    let errorType2 = undefined;
    let errorType3 = undefined;

    const MSG = {
      to: ["joseph@venturepoint.co.za", "info@epictimber.co.za"],
      from: "info@epic.co.za",
      subject: "New contact request",
      text: " ",
      html: `
    
      <style type="text/css">  
            body, #bodyTable{background-color:white}
            #emailHeader{background-color:white}
            #emailBody{background-color:#FFFFFF; border:1px solid #CCCCCC;}
            #emailFooter{background-color:#E1E1E1;}      
    </style>
  <body bgcolor="#E1E1E1" leftmargin="0" marginwidth="0" topmargin="0" marginheight="0" offset="0">
    <center style="background-color:#E1E1E1;">
      <table border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="bodyTable" style="table-layout: fixed;max-width:100% !important;width: 100% !important;min-width: 100% !important;">
        <tr>
          <td align="center" valign="top" id="bodyCell">      
            <table bgcolor="#E1E1E1" border="0" cellpadding="0" cellspacing="0" width="500" id="emailHeader">
              <!-- HEADER ROW // -->
              <tr>
                <td align="center" valign="top">
                  <!-- CENTERING TABLE // -->
                  <table border="0" cellpadding="0" cellspacing="0" width="100%">
                    <tr>
                      <td align="center" valign="top">
                        <!-- FLEXIBLE CONTAINER // -->
                        <table border="0" cellpadding="10" cellspacing="0" width="500" class="flexibleContainer">
                          <tr>
                            <td valign="top" width="500" class="flexibleContainerCell">
  
                              <!-- CONTENT TABLE // -->
                              <table align="left" border="0" cellpadding="0" cellspacing="0" width="100%">
                                <tr>
                             
                                  <td align="right" valign="middle" class="flexibleContainerBox">
                                    <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width:100%;">
                                      <tr>
                                        <td align="left" class="textContent">
                                          <!-- CONTENT // -->
                                         
                                        </td>
                                      </tr>
                                    </table>
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                        <!-- // FLEXIBLE CONTAINER -->
                      </td>
                    </tr>
                  </table>
                  <!-- // CENTERING TABLE -->
                </td>
              </tr>
              <!-- // END -->
            </table>     
            <!-- EMAIL BODY // -->   
            <table bgcolor="#FFFFFF" border="0" cellpadding="0" cellspacing="0" width="500" id="emailBody">
              <!-- MODULE ROW // -->
              <tr>
                <td align="center" valign="top">
                  <!-- CENTERING TABLE // -->
                  <table border="0" cellpadding="0" cellspacing="0"   style="color:#FFFFFF;" bgcolor="#744a31">
                    <tr>
                      <td align="center" valign="top">
                        <!-- FLEXIBLE CONTAINER // -->
                       
                        <table border="0" cellpadding="0" cellspacing="0" width="500"   class="flexibleContainer">
                          <tr>
                            <td align="center" valign="top" width="500" class="flexibleContainerCell">
  
                              <!-- CONTENT TABLE // -->
                            
                              <table border="0" cellpadding="0" cellspacing="0" width="100%" z-index="100" >
                                     <td align="center" valign="top" width="500" class="flexibleContainerCell  ">   
                                      <img src="https://www.epictimber.co.za/assets/epic-timber-logo.png"/>    <tr>
                                  <td align="center" valign="top" class="textContent">
                                    <h1 style="color:#FFFFFF;line-height:100%;font-family:Helvetica,Arial,sans-serif;font-size:35px;font-weight:normal;margin-bottom:20px;text-align:center;">Contact Request</h1>
                                    <h2 style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:17px;margin-bottom:10px;color: #FFFFFF;line-height:135%;">You have received a new contact request from <br /> the Epic Timber website</h2>
                     
                                  </td>
                                </tr>
                              </table>
                              <!-- // CONTENT TABLE -->
  
                            </td>
                          </tr>
                        </table>
                        <!-- // FLEXIBLE CONTAINER -->
                      </td>
                    </tr>
                  </table>
                  <!-- // CENTERING TABLE -->
                </td>
              </tr>

              <!-- MODULE ROW // -->
              
              <tr mc:hideable>
                <td align="center" valign="top">
                  <!-- CENTERING TABLE // -->
                  <table border="0" cellpadding="0" cellspacing="0" width="100%" height="300" >
                    <tr>
                      <td align="center" valign="top">
                        <!-- FLEXIBLE CONTAINER // -->
                        <table border="0" cellpadding="0" cellspacing="0" width="300" class="flexibleContainer">
                          <tr>
                            <td valign="top" width="500" class="flexibleContainerCell">
                              <!-- CONTENT TABLE // -->
                              <table p align="left" border="0" cellpadding="0" cellspacing="0" width="100%">
                                <tr>
                                  <td align="center" valign="top" class="flexibleContainerBox">
                                   	<table border="0" cellspacing="0" cellpadding="0" >
									
															<tr style="font-family: sans-serif; font-size: 15px; letter-spacing: 1px; color:rgb(121, 121, 121)">
																<td class="role"><br /><br /><br /><br /><br />
                                <strong> Name </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${this.state.name}<br />
                                     <strong> Email </strong>&nbsp;&nbsp;: &nbsp;&nbsp;${this.state.email}<br />
                                     <strong> Number </strong>&nbsp;&nbsp; :                     &nbsp;&nbsp;${this.state.phonenumber}<br />
                                     <strong> Company name </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${this.state.company}<br />
                                     <strong> Message </strong>&nbsp;&nbsp;:&nbsp;&nbsp; ${this.state.message}<br /><br /><br /><br /><br />
																</td>
															</tr>
															<table>
                                  </td>
                                </tr>
                              </table>
                              <!-- // CONTENT TABLE -->
  
                            </td>
                          </tr>
                        </table>
                        <!-- // FLEXIBLE CONTAINER -->
                      </td>
                    </tr>
                  </table>
                  <!-- // CENTERING TABLE -->
                </td>
              </tr>
              <!-- // MODULE ROW -->
  
  
              <!-- MODULE ROW // -->
             
<table border="0" cellpadding="0" cellspacing="0" width="100%"  style="color:#FFFFFF;" bgcolor="#303030">
                    <tr>
                      <td align="center" valign="top">
                      
                        <table border="0" cellpadding="0" cellspacing="0" width="500"   class="flexibleContainer">
                          <tr>
                            <td align="center" valign="top" width="500" class="flexibleContainerCell">
  
                          
                              <table border="0" cellpadding="30" cellspacing="0" width="100%">
                            
													
                                <tr>
                                  <td align="center" valign="top" class="textContent">
              
                                    <h2 style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:13px;margin-bottom:0px;color: lightgray;line-height:135%;">&#9400 2019 Epic Timber</h2>
                     
                                  </td>
                                </tr>
                              </table>
                              <!-- // CONTENT TABLE -->
  
                            </td>
                          </tr>
                        </table>
                        <!-- // FLEXIBLE CONTAINER -->
                      </td>
                    </tr>
                  </table>
           `,
    };

    if (this.state.name === "") {
      formValidity = false;
      errorType1 = this.state.formErrors.name;
    }
    if (!validator.isEmail(this.state.email)) {
      formValidity = false;
      errorType2 = this.state.formErrors.email;
    }
    if (!validator.isMobilePhone(this.state.phonenumber)) {
      formValidity = false;
      errorType3 = this.state.formErrors.phonenumber;
    }
    if (!formValidity) {
      this.setState({
        formValidity,
        errorType,
        errorType1,
        errorType2,
        errorType3,
      });
    } else {
      fetch("https://www.devvcsappcloud.com/emailclient/sendmail", {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify(MSG),
      }).then(async (res) => {
        if (!res.ok) {
          let response = await res.text();
          this.setState({
            formValidity,
            errorType: undefined,
            errorType1: undefined,
            errorType2: undefined,
            errorType3: undefined,
            submitDisabled: true,
            name: "",
            email: "",
            phonenumber: "",
            company: "",
            message: "",
          });
        }
        this.setState({
          successModal: true,
          formValidity,
          errorType: undefined,
          errorType1: undefined,
          errorType2: undefined,
          errorType3: undefined,
          submitDisabled: true,
          name: "",
          email: "",
          phonenumber: "",
          company: "",
          message: "",
        });
      });
    }
  };

  render() {
    return (
      <React.Fragment>
        <TopHeader />
        <Content style={{ padding: "0 50px", zIndex: -99 }}>
          <Row gutter={12}>
            <Col span={12}>
              <h1
                style={{
                  fontSize: "28px",
                  fontWeight: 700,
                  paddingTop: "6em",
                  marginLeft: "13%",
                }}
              >
                {" "}
                <Fade duration={1100} delay={500} top>
                  <h1
                    style={{
                      fontSize: "50px",
                      fontWeight: 700,
                      lineHeight: "1em",
                    }}
                  >
                    Contact Us
                  </h1>
                </Fade>
              </h1>
              <Fade duration={1500} delay={500} bottom>
                <p
                  style={{
                    fontSize: "20px",
                    fontWeight: 400,
                    marginTop: "0em",
                    marginLeft: "13%",
                  }}
                >
                  Fill out this form and a VPOS team
                  <br /> member will reply to your question in <br />
                  writing. If you want to talk with us,
                  <br /> include your phone number so we
                  <br /> can contact you to speak.
                  <br />
                </p>
              </Fade>{" "}
              <Fade duration={1000} delay={500} left>
                <img src={support} alt="Logo" className="support-pic" />
              </Fade>
              <Fade duration={1000} delay={500} right>
                <p
                  style={{
                    fontSize: "20px",
                    fontWeight: 400,
                    marginTop: "4em",
                    marginLeft: "25%",
                    marginBottom: "20em",
                  }}
                >
                  {" "}
                  Looking for commercial support?
                  <br /> If you’re already a VPOS customer and
                  <br /> need support, fill the form and we will
                  <br /> contact you as soon as possible
                </p>
              </Fade>{" "}
            </Col>

            <Col span={12}>
              <div
                style={{
                  position: "absolute",
                  align: "center",
                  right: "40%",
                  marginTop: "10em",
                  width: "50%",
                  maxWidth: "40em",
                }}
              >
                <h1
                  style={{
                    width: "36em",
                    fontSize: "17px",
                    marginLeft: "0em",
                    marginTop: "-1.5em",
                    color: "grey",
                    position: "absolute",
                  }}
                >
                  Email Address
                </h1>
                <Input
                  autoComplete="off"
                  type="email"
                  style={{
                    marginBottom: "2em",
                  }}
                  size="large"
                  placeholder=""
                />{" "}
                <h1
                  style={{
                    width: "36em",
                    fontSize: "17px",
                    marginLeft: "0em",
                    marginTop: "-1.5em",

                    color: "grey",
                    position: "absolute",
                  }}
                >
                  First Name
                </h1>
                <Input
                  style={{
                    marginBottom: "2em",
                  }}
                  size="large"
                  placeholder=""
                />{" "}
                <h1
                  style={{
                    width: "36em",
                    fontSize: "17px",
                    marginLeft: "0em",
                    marginTop: "-1.5em",

                    color: "grey",
                    position: "absolute",
                  }}
                >
                  Last Name
                </h1>
                <Input
                  style={{
                    marginBottom: "2em",
                  }}
                  size="large"
                  placeholder=""
                />{" "}
                <h1
                  style={{
                    width: "36em",
                    fontSize: "17px",
                    marginLeft: "0em",
                    marginTop: "-1.5em",

                    color: "grey",
                    position: "absolute",
                  }}
                >
                  Company Name
                </h1>
                <Input
                  style={{
                    marginBottom: "2em",
                  }}
                  size="large"
                  placeholder=""
                />{" "}
                <h1
                  style={{
                    width: "36em",
                    fontSize: "17px",
                    marginLeft: "0em",
                    marginTop: "-1.5em",

                    color: "grey",
                    position: "absolute",
                  }}
                >
                  Inquiry Type
                </h1>
                <Input
                  style={{
                    marginBottom: "2em",
                  }}
                  size="large"
                  placeholder=""
                />{" "}
                <h1
                  style={{
                    width: "36em",
                    fontSize: "17px",
                    marginLeft: "0em",
                    marginTop: "-1.5em",

                    color: "grey",
                    position: "absolute",
                  }}
                >
                  Message
                </h1>
                <TextArea
                  style={{
                    height: "12em",
                    fontSize: "1.1em",
                  }}
                  placeholder=""
                  autoSize={{ minRows: 6, maxRows: 6 }}
                />
                <Checkbox
                  style={{
                    transform: "scale(1.16)",
                    marginLeft: "2em",
                    marginTop: "2em",
                  }}
                  onChange={onChange}
                >
                  {" "}
                  <h1
                    style={{
                      width: "36em",
                      fontSize: "9px",
                      marginLeft: "2.5em",
                      marginTop: "-2.3em",
                      color: "grey",
                    }}
                  >
                    By checking this box, I consent to receive emails from VPOS
                    with product
                    <br /> updates, promotions, and company news. I have read
                    the Privacy Policy and
                    <br /> understand that I can unsubscribe at any time.
                  </h1>
                </Checkbox>
                <Button
                  style={{
                    fontSize: "17px",
                    fontWeight: 500,
                    marginLeft: "0%",
                  }}
                  className="submit-buttons"
                  type="primary"
                >
                  Learn More
                </Button>
              </div>
            </Col>
          </Row>
          <img
            src={wave}
            alt="Logo"
            style={{
              marginLeft: "-4em",
              width: "115%",
              minHeight: "30em",
            }}
          />{" "}
          <div
            style={{
              position: "absolute",
              align: "center",
              right: "55%",
              width: "27em",
            }}
          >
            <h1
              style={{
                fontSize: "20px",
                marginTop: "-8em",
                width: "27em",
                position: "absolute",
                color: "white",
              }}
            >
              {" "}
              <h1
                style={{
                  paddingLeft: "0em",
                  fontSize: "30px",
                  fontWeight: 700,
                  marginTop: "-1.5em",
                  left: "41%",
                  color: "white",
                  position: "absolute",
                }}
              >
                Get Started with VPOS
              </h1>{" "}
              Drive Sales and boost customer experience with Innovation
            </h1>{" "}
            <div>
              {" "}
              <a href="https://www.devvcsappcloud.com/verkoopnou/signup">
                <Button
                  style={{
                    marginTop: "-11.7em",
                    position: "absolute",
                  }}
                  className="get-started-buttons"
                  type="primary"
                >
                  Request a Demo
                </Button>{" "}
              </a>
            </div>
          </div>
          <BottomFooter />
        </Content>
      </React.Fragment>
    );
  }
}

export default ContactUs;

import React, { Component } from "react";
import { Layout, Card, Button, Select, Col, Row, Icon } from "antd";
import logoWhite from "../static/images/vpos-logo-white.png";
import robust from "../static/images/robust.png";
import people from "../static/images/people.png";
import restaurant from "../static/images/restaurant.png";
import inventory from "../static/images/inventory.png";
import wave from "../static/images/wave.png";
import logo from "../static/images/vpos-logo.png";
import apple from "../static/images/apple.png";
import google from "../static/images/google.png";
import salesPic from "../static/images/sales-screen-pic.png";
import adminPic from "../static/images/admin-pic.png";
import tabletMock from "../static/images/tablet-mockup.png";
import payMethodPic from "../static/images/pay-method-pic.png";
import statPic from "../static/images/stat-pic.png";
import serverless from "../static/images/serverless.png";
import backOffice from "../static/images/back-office.png";
import cuttingEdge from "../static/images/cutting-edge.png";
import dotLine1 from "../static/images/dot-line-1.png";
import circle1 from "../static/images/circle-1.png";
import circle2 from "../static/images/circle-2.png";
import circle3 from "../static/images/circle-3.png";
import circleBig from "../static/images/circle-big.png";
import "./sales-screen.css";
import Fade from "react-reveal/Fade";
import Flip from "react-reveal/Flip";
import RubberBand from "react-reveal/RubberBand";
import { Link } from "react-router-dom";
const { Header, Content, Footer } = Layout;

const { Option } = Select;

const TopHeader = () => {
  return (
    <Header className="header-home">
      <img src={logo} alt="Logo" className="verkoopnou-logo" />

      <div></div>
      <div className="header-links">
        <Link to="/">
          <a
            style={{
              color: "black",
              fontSize: "17px",
              fontWeight: 500,
              paddingLeft: "20%",
            }}
          >
            Home
          </a>{" "}
        </Link>
        <a href="/features">
          <a
            style={{
              color: "black",
              fontSize: "17px",
              fontWeight: 500,
              paddingLeft: "8%",
            }}
          >
            Features
          </a>
        </a>
        <a
          style={{
            color: "black",
            fontSize: "17px",
            fontWeight: 500,
            paddingLeft: "8%",
          }}
          href="/contact-us"
        >
          Contact
        </a>{" "}
        <a href="https://www.devvcsappcloud.com/verkoopnou/signin">
          <Button
            style={{
              fontSize: "17px",
              fontWeight: 500,
              marginLeft: "18%",
            }}
            id="headerButton"
            className="features-header-buttons"
            type="primary"
          >
            Learn More
          </Button>
        </a>
      </div>
    </Header>
  );
};
const BottomFooter = () => {
  return (
    <Footer className="bottom-footer">
      <div
        style={{
          backgroundColor: "#171717",
          width: "110%",
          marginLeft: "-3em",
        }}
      >
        <img src={logoWhite} alt="Logo" className="footer-logo" />
        <img src={apple} alt="Logo" className="footer-logo-apple" />
        <img src={google} alt="Logo" className="footer-logo-google" />

        <div className="footer-links-div">
          <h1 style={{ color: "white", fontSize: "20px" }}>Company</h1>
          <Link to="/" style={{ color: "white" }}>
            Home
          </Link>
          <br />
          <a>
            <a style={{ color: " white" }}>Terms</a>
          </a>
          <br />
          <Link to="">
            <a
              href="https://www.devvcsappcloud.com/verkoopnou/signup"
              style={{ color: " white" }}
            >
              Privacy Policy
            </a>{" "}
          </Link>
          <br />{" "}
          <Link>
            <a style={{ color: " white" }}>Cookie Policy</a>
          </Link>
          <br />
        </div>
        <div className="footer-text">
          <h1 style={{ color: "white", fontSize: "20px" }}>Contact</h1>
          <p style={{ color: " white" }}>
            11-04 Phoenix View Estate
            <br />
            Midrand
            <br /> South Africa
            <br />
            www.venturepoint.co.za
          </p>
          <Icon
            type="facebook"
            theme="filled"
            style={{
              paddingRight: "0.5em",
              fontSize: "2em",
              color: " white",
            }}
            url="https://www.facebook.com/"
          />
          <Icon
            type="linkedin"
            theme="filled"
            style={{ paddingRight: "0.5em", fontSize: "2em", color: " white" }}
          />
        </div>
        <div style={{ textAlign: "left" }} className="footer-watermark">
          <p style={{ color: "white" }}>
            Copyright © 2020.{" "}
            <Link to="https://www.venturepoint.co.za">
              <a style={{ color: "white" }}>
                {" "}
                Venturepoint Cosnulting Services
              </a>
            </Link>
            All rights reserved.
          </p>
        </div>
      </div>
    </Footer>
  );
};
function handleChange(value) {
  console.log(`selected ${value}`);
}
class SalesScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <div
          id="home"
          style={{
            background: "white",
            top: "0em",
            border: "0px solid #8888882c",
            boxShadow: "none",
            width: "100vw",
            zIndex: "0",
            height: "100vh",
          }}
        >
          <TopHeader />

          <div>
            <h1 className="features-home-content-text">
              <Fade duration={1100} delay={200} left cascade>
                The tools to help you increase
              </Fade>
              <br />{" "}
              <Fade duration={1100} delay={300} left cascade>
                sales and build your business
              </Fade>
              <br />{" "}
              <Row style={{ marginTop: "12%", marginRight: "-30%" }}>
                <Col style={{ marginRight: "-8%" }} span={6}>
                  <Flip top delay={400}>
                    <img
                      src={robust}
                      alt="Logo"
                      style={{
                        position: "absolute",
                        marginLeft: "-0.5em",
                        textAlign: "center",
                        width: "1em",
                      }}
                    />{" "}
                    <h1
                      style={{
                        fontSize: "20px",
                        fontWeight: 700,
                        marginTop: "4em",
                        textAlign: "center",
                        color: "black",
                      }}
                    >
                      Robust Point of Sale
                    </h1>{" "}
                  </Flip>
                </Col>

                <Col style={{ marginRight: "-8%" }} span={6}>
                  <Flip top delay={500}>
                    <img
                      src={inventory}
                      alt="Logo"
                      style={{
                        position: "absolute",
                        marginLeft: "-0.5em",
                        textAlign: "center",
                        width: "1em",
                      }}
                    />{" "}
                    <h1
                      style={{
                        fontSize: "20px",
                        fontWeight: 700,
                        marginTop: "4em",
                        textAlign: "center",
                        color: "black",
                      }}
                    >
                      Manage your inventory
                    </h1>{" "}
                  </Flip>
                </Col>
                <Col style={{ marginRight: "-8%" }} span={6}>
                  <Flip bottom delay={600}>
                    <img
                      src={restaurant}
                      alt="Logo"
                      style={{
                        position: "absolute",
                        marginLeft: "-0.5em",
                        textAlign: "center",
                        width: "1em",
                      }}
                    />{" "}
                    <h1
                      style={{
                        fontSize: "20px",
                        fontWeight: 700,
                        marginTop: "4em",
                        textAlign: "center",
                        color: "black",
                      }}
                    >
                      Restaurant features
                    </h1>{" "}
                  </Flip>
                </Col>
                <Col span={6}>
                  <Flip top delay={700}>
                    <img
                      src={people}
                      alt="Logo"
                      style={{
                        position: "absolute",
                        marginLeft: "-0.5em",
                        textAlign: "center",
                        width: "1em",
                      }}
                    />{" "}
                    <h1
                      style={{
                        fontSize: "20px",
                        fontWeight: 700,
                        marginTop: "4em",
                        textAlign: "center",
                        color: "black",
                      }}
                    >
                      Manage your people
                    </h1>{" "}
                  </Flip>
                </Col>
              </Row>
            </h1>
          </div>
        </div>
        <Content style={{ padding: "0 50px" }}>
          <img src={dotLine1} alt="Logo" className="dot-line-1" />
          <div id="middle">
            <Row gutter={24}>
              <Fade duration={1100} delay={200} left cascade>
                <h1
                  className="features-content-text-1"
                  style={{
                    fontSize: "40px",

                    fontWeight: "700",
                  }}
                >
                  <img
                    src={robust}
                    alt="Logo"
                    style={{
                      width: "1.3em",
                      marginTop: "-0.4em",
                      marginRight: "0.5em",
                    }}
                  />{" "}
                  Point of Sale
                </h1>{" "}
              </Fade>
              <Col span={24}>
                <img src={payMethodPic} alt="Logo" className="pay-method-pic" />
                <h1
                  style={{
                    fontSize: "28px",
                    fontWeight: 700,
                    paddingTop: "3em",
                    marginLeft: "58.9%",
                  }}
                >
                  {" "}
                  <Fade duration={1100} delay={500} top>
                    <h1
                      style={{
                        fontSize: "30px",
                        fontWeight: 700,
                        lineHeight: "1em",
                      }}
                    >
                      Cross platform
                    </h1>
                  </Fade>
                </h1>
                <Fade duration={1500} delay={500} bottom>
                  <p
                    style={{
                      fontSize: "20px",
                      fontWeight: 400,
                      marginTop: "0em",
                      marginLeft: "58.9%",
                    }}
                  >
                    Works on iOS, Android and Web. Available on
                    <br /> Playstore and AppStore.
                    <br />
                  </p>
                </Fade>
                <h1
                  style={{
                    fontSize: "28px",
                    fontWeight: 700,
                    paddingTop: "1.8em",
                    marginLeft: "58.9%",
                  }}
                >
                  {" "}
                  <Fade duration={1100} delay={500} top>
                    <h1
                      style={{
                        fontSize: "30px",
                        fontWeight: 700,
                        lineHeight: "1em",
                      }}
                    >
                      Electronic receipts
                    </h1>
                  </Fade>
                </h1>
                <Fade duration={1500} delay={500} bottom>
                  <p
                    style={{
                      fontSize: "20px",
                      fontWeight: 400,
                      marginTop: "0em",
                      marginLeft: "58.9%",
                    }}
                  >
                    Save paper by sending an electronic receipt to
                    <br /> customers via email.
                    <br />
                  </p>
                </Fade>{" "}
                <h1
                  style={{
                    fontSize: "28px",
                    fontWeight: 700,
                    paddingTop: "2em",
                    marginLeft: "58.9%",
                  }}
                >
                  {" "}
                  <Fade duration={1100} delay={500} top>
                    <h1
                      style={{
                        fontSize: "30px",
                        fontWeight: 700,
                        lineHeight: "1em",
                      }}
                    >
                      Multiple payment methods
                    </h1>
                  </Fade>
                </h1>
                <Fade duration={1500} delay={500} bottom>
                  <p
                    style={{
                      fontSize: "20px",
                      fontWeight: 400,
                      marginTop: "0em",
                      marginLeft: "58.9%",
                    }}
                  >
                    Make payments with cash , card or <br />
                    integration with leading payments solutions
                    <br />
                  </p>
                </Fade>{" "}
                <h1
                  style={{
                    fontSize: "28px",
                    fontWeight: 700,
                    paddingTop: "2.3em",
                    marginLeft: "58.9%",
                  }}
                >
                  {" "}
                  <Fade duration={1100} delay={500} top>
                    <h1
                      style={{
                        fontSize: "30px",
                        fontWeight: 700,
                        lineHeight: "1em",
                      }}
                    >
                      Open bills
                    </h1>
                  </Fade>
                </h1>
                <Fade duration={1500} delay={500} bottom>
                  <p
                    style={{
                      fontSize: "20px",
                      fontWeight: 400,
                      marginTop: "0em",
                      marginLeft: "58.9%",
                    }}
                  >
                    Create bills simultaneously with option to <br />
                    save. Customers pay when it's convenient.
                    <br />
                  </p>
                </Fade>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <img src={statPic} alt="Logo" className="stat-pic" />
                <h1
                  style={{
                    fontSize: "28px",
                    fontWeight: 700,
                    paddingTop: "11.6em",
                    marginLeft: "7.5%",
                  }}
                >
                  {" "}
                  <Fade duration={1100} delay={500} top>
                    <h1
                      style={{
                        fontSize: "30px",
                        fontWeight: 700,
                        lineHeight: "1em",
                      }}
                    >
                      Cash Management
                    </h1>
                  </Fade>
                </h1>
                <Fade duration={1500} delay={500} bottom>
                  <p
                    style={{
                      fontSize: "20px",
                      fontWeight: 400,
                      marginTop: "0em",
                      marginLeft: "7.5%",
                    }}
                  >
                    Manage and track cashflow to identify errors <br />
                    and manipulation.
                    <br />
                  </p>
                </Fade>
                <h1
                  style={{
                    fontSize: "28px",
                    fontWeight: 700,
                    paddingTop: "1.6em",
                    marginLeft: "7.5%",
                  }}
                >
                  {" "}
                  <Fade duration={1100} delay={500} top>
                    <h1
                      style={{
                        fontSize: "30px",
                        fontWeight: 700,
                        lineHeight: "1em",
                      }}
                    >
                      Work Offline
                    </h1>
                  </Fade>
                </h1>
                <Fade duration={1500} delay={500} bottom>
                  <p
                    style={{
                      fontSize: "20px",
                      fontWeight: 400,
                      marginTop: "0em",
                      marginLeft: "7.5%",
                    }}
                  >
                    Sell offline and data will automatically sync once <br />
                    whenever you come online.
                    <br />
                  </p>
                </Fade>{" "}
                <h1
                  style={{
                    fontSize: "28px",
                    fontWeight: 700,
                    paddingTop: "2.1em",
                    marginLeft: "7.5%",
                  }}
                >
                  {" "}
                  <Fade duration={1100} delay={500} top>
                    <h1
                      style={{
                        fontSize: "30px",
                        fontWeight: 700,
                        lineHeight: "1em",
                      }}
                    >
                      Hardware
                    </h1>
                  </Fade>
                </h1>
                <Fade duration={1500} delay={500} bottom>
                  <p
                    style={{
                      fontSize: "20px",
                      fontWeight: 400,
                      marginTop: "0em",
                      marginLeft: "7.5%",
                    }}
                  >
                    Inlcudes wireless receipt printer, wireless
                    <br /> barcode scanner and cash drawer. including
                    <br /> USB connectors.
                    <br />
                  </p>
                </Fade>{" "}
                <h1
                  style={{
                    fontSize: "28px",
                    fontWeight: 700,
                    paddingTop: "1.2em",
                    marginLeft: "7.5%",
                  }}
                >
                  {" "}
                  <Fade duration={1100} delay={500} top>
                    <h1
                      style={{
                        fontSize: "30px",
                        fontWeight: 700,
                        lineHeight: "1em",
                      }}
                    >
                      Multiple locations
                    </h1>
                  </Fade>
                </h1>
                <Fade duration={1500} delay={500} bottom>
                  <p
                    style={{
                      fontSize: "20px",
                      fontWeight: 400,
                      marginTop: "0em",
                      marginLeft: "7.5%",
                    }}
                  >
                    Manage sales, inventory, employees and
                    <br /> customers in multiple stores, all in one place.
                    <br />
                  </p>
                </Fade>
              </Col>
            </Row>
            <div
              style={{
                textAlign: "center",
              }}
            >
              {" "}
              <Fade duration={1100} delay={200} left cascade>
                <h1
                  style={{
                    fontSize: "37px",
                    fontWeight: 700,
                    marginTop: "4em",
                    marginLeft: "1em",
                    marginBottom: "2em",
                    textAlign: "left",
                    color: "black",
                  }}
                >
                  {" "}
                  <img
                    src={inventory}
                    alt="Logo"
                    style={{
                      width: "1.3em",
                      marginTop: "-0.4em",
                      marginRight: "0.5em",
                    }}
                  />{" "}
                  Inventory Mananagement
                </h1>{" "}
              </Fade>
              <Row
                style={{
                  marginLeft: "-2em",
                }}
              >
                <Col span={12}>
                  <h1
                    style={{
                      fontSize: "20px",
                      fontWeight: 700,
                      marginTop: "4em",
                      marginRight: "8em",
                      textAlign: "right",
                      color: "black",
                    }}
                  >
                    Import and Export Items
                    <Fade duration={1500} delay={500} left>
                      <p
                        style={{
                          textAlign: "right",
                          fontSize: "20px",
                          fontWeight: 400,
                          marginTop: "1em",
                          marginLeft: "-7.5%",
                        }}
                      >
                        Track cash movement to reduce mistakes and
                        <br /> staff manipulation.
                        <br />
                      </p>
                    </Fade>
                  </h1>{" "}
                </Col>

                <Col span={12}>
                  <h1
                    style={{
                      fontSize: "20px",
                      fontWeight: 700,
                      marginTop: "4em",
                      marginLeft: "8em",
                      textAlign: "left",
                      color: "black",
                    }}
                  >
                    Inventory Counts
                    <Fade duration={1500} delay={500} right>
                      <p
                        style={{
                          textAlign: "left",
                          fontSize: "20px",
                          fontWeight: 400,
                          marginTop: "1em",
                          marginLeft: "-0%",
                        }}
                      >
                        Perform full or partial stock takes with a<br /> barcode
                        scanner or manually.
                        <br />
                      </p>
                    </Fade>
                  </h1>{" "}
                </Col>
              </Row>
              <Row
                style={{
                  marginLeft: "-2em",
                }}
              >
                <Col span={12}>
                  <h1
                    style={{
                      fontSize: "20px",
                      fontWeight: 700,
                      marginTop: "4em",
                      marginRight: "8em",
                      textAlign: "right",
                      color: "black",
                    }}
                  >
                    Low Stock Notifications
                    <Fade duration={1500} delay={500} left>
                      <p
                        style={{
                          textAlign: "right",
                          fontSize: "20px",
                          fontWeight: 400,
                          marginTop: "1em",
                          marginLeft: "-7.5%",
                        }}
                      >
                        Receive daily notifications about low stock to <br />
                        make the necessary item orders in time.
                        <br />
                      </p>
                    </Fade>
                  </h1>{" "}
                </Col>

                <Col span={12}>
                  <h1
                    style={{
                      fontSize: "20px",
                      fontWeight: 700,
                      marginTop: "4em",
                      marginLeft: "8em",
                      textAlign: "left",
                      color: "black",
                    }}
                  >
                    Forecasting
                    <Fade duration={1500} delay={500} right>
                      <p
                        style={{
                          textAlign: "left",
                          fontSize: "20px",
                          fontWeight: 400,
                          marginTop: "1em",
                          marginLeft: "-0%",
                        }}
                      >
                        Avoid stock-outs and minimize costs from
                        <br /> holding excess inventory.
                        <br />
                      </p>
                    </Fade>
                  </h1>{" "}
                </Col>
              </Row>
              <Row
                style={{
                  marginLeft: "-2em",
                }}
              >
                <Col span={12}>
                  <h1
                    style={{
                      fontSize: "20px",
                      fontWeight: 700,
                      marginTop: "4em",
                      marginRight: "8em",
                      textAlign: "right",
                      color: "black",
                    }}
                  >
                    Purchase orders
                    <Fade duration={1500} delay={500} left>
                      <p
                        style={{
                          textAlign: "right",
                          fontSize: "20px",
                          fontWeight: 400,
                          marginTop: "1em",
                          marginLeft: "-7.5%",
                        }}
                      >
                        Track cash movement to reduce mistakes and
                        <br /> staff manipulation.
                        <br />
                      </p>
                    </Fade>
                  </h1>{" "}
                </Col>

                <Col span={12}>
                  <h1
                    style={{
                      fontSize: "20px",
                      fontWeight: 700,
                      marginTop: "4em",
                      marginLeft: "8em",
                      textAlign: "left",
                      color: "black",
                    }}
                  >
                    Inventory History
                    <Fade duration={1500} delay={500} right>
                      <p
                        style={{
                          textAlign: "left",
                          fontSize: "20px",
                          fontWeight: 400,
                          marginTop: "1em",
                          marginLeft: "-0%",
                        }}
                      >
                        Gain insights into the flow of your inventory by <br />
                        viewing adjustment log.
                        <br />
                      </p>
                    </Fade>
                  </h1>{" "}
                </Col>
              </Row>
              <Row
                style={{
                  marginLeft: "-2em",
                }}
              >
                <Col span={12}>
                  <h1
                    style={{
                      fontSize: "20px",
                      fontWeight: 700,
                      marginTop: "4em",
                      marginRight: "8em",
                      textAlign: "right",
                      color: "black",
                    }}
                  >
                    Import and Export items
                    <Fade duration={1500} delay={500} left>
                      <p
                        style={{
                          textAlign: "right",
                          fontSize: "20px",
                          fontWeight: 400,
                          marginTop: "1em",
                          marginLeft: "-7.5%",
                        }}
                      >
                        Track cash movement to reduce mistakes and <br />
                        staff manipulation.
                        <br />
                      </p>
                    </Fade>
                  </h1>{" "}
                </Col>

                <Col span={12}>
                  <h1
                    style={{
                      fontSize: "20px",
                      fontWeight: 700,
                      marginTop: "4em",
                      marginLeft: "8em",
                      textAlign: "left",
                      color: "black",
                    }}
                  >
                    Inventory Valuation Report
                    <Fade duration={1500} delay={500} right>
                      <p
                        style={{
                          textAlign: "left",
                          fontSize: "20px",
                          fontWeight: 400,
                          marginTop: "1em",
                          marginLeft: "-0%",
                        }}
                      >
                        View report on the cost and potential profit of <br />
                        your inventory.
                        <br />
                      </p>
                    </Fade>
                  </h1>{" "}
                </Col>
              </Row>
              <div
                style={{
                  backgroundColor: "rgba(36, 173, 161, 0.199)",
                  marginLeft: "-4em",

                  width: "110vw",
                }}
              >
                <Fade duration={1100} delay={200} left cascade>
                  <h1
                    style={{
                      fontSize: "37px",
                      fontWeight: 700,
                      marginTop: "4em",
                      marginLeft: "2em",
                      marginBottom: "0em",
                      paddingTop: "2em",
                      paddingLeft: "2em",
                      textAlign: "left",
                      color: "black",
                    }}
                  >
                    {" "}
                    <img
                      src={restaurant}
                      alt="Logo"
                      style={{
                        width: "1.3em",
                        marginTop: "-0.4em",
                        marginRight: "0.5em",
                      }}
                    />{" "}
                    Restaurant & Bar
                  </h1>{" "}
                </Fade>
                <Row
                  style={{
                    marginLeft: "12em",
                  }}
                >
                  {" "}
                  <img src={salesPic} className="sales-pic" alt="Logo" />{" "}
                  <Col span={12}>
                    <h1
                      style={{
                        fontSize: "20px",
                        fontWeight: 700,
                        marginTop: "4em",
                        textAlign: "left",
                        color: "black",
                      }}
                    >
                      Kitchen Printers
                      <Fade duration={1500} delay={500} bottom>
                        <p
                          style={{
                            textAlign: "left",
                            fontSize: "20px",
                            fontWeight: 400,
                            marginTop: "1em",
                          }}
                        >
                          Perform full or partial stock takes with a <br />
                          barcode scanner or manually.
                          <br />
                        </p>
                      </Fade>
                    </h1>{" "}
                  </Col>
                </Row>
                <Row
                  style={{
                    marginLeft: "12em",
                  }}
                >
                  <Col span={12}>
                    <h1
                      style={{
                        fontSize: "20px",
                        fontWeight: 700,
                        marginTop: "1.5em",

                        textAlign: "left",
                        color: "black",
                      }}
                    >
                      Kitchen display system
                      <Fade duration={1500} delay={500} bottom>
                        <p
                          style={{
                            textAlign: "left",
                            fontSize: "20px",
                            fontWeight: 400,
                            marginTop: "1em",
                          }}
                        >
                          Get orders to kitchen automatically, faster and
                          <br /> with less errors. Less paper, less mess. Save
                          <br /> and go green.
                          <br />
                        </p>
                      </Fade>
                    </h1>{" "}
                  </Col>
                </Row>
                <Row
                  style={{
                    marginLeft: "12em",
                  }}
                >
                  <Col span={12}>
                    <h1
                      style={{
                        fontSize: "20px",
                        fontWeight: 700,
                        marginTop: "1.5em",

                        textAlign: "left",
                        color: "black",
                      }}
                    >
                      Dining Options
                      <Fade duration={1500} delay={500} bottom>
                        <p
                          style={{
                            textAlign: "left",
                            fontSize: "20px",
                            fontWeight: 400,
                            marginTop: "1em",
                          }}
                        >
                          Note whether customers are dining in, taking,
                          <br />
                          their order out, or requesting delivery.
                          <br />
                        </p>
                      </Fade>
                    </h1>{" "}
                  </Col>
                </Row>
                <Row
                  style={{
                    marginLeft: "12em",
                  }}
                >
                  <Col span={12}>
                    <h1
                      style={{
                        fontSize: "20px",
                        fontWeight: 700,
                        marginTop: "1.5em",

                        textAlign: "left",
                        color: "black",
                      }}
                    >
                      Cooking Instruction
                      <Fade duration={1500} delay={500} bottom>
                        <p
                          style={{
                            textAlign: "left",
                            fontSize: "20px",
                            fontWeight: 400,
                            marginTop: "1em",
                            marginBottom: "8em",
                          }}
                        >
                          Modify orders easily. Choose add-ons to dishes
                          <br /> or how they're prepared in one click.
                          <br />
                        </p>
                      </Fade>
                    </h1>{" "}
                  </Col>
                </Row>
              </div>
              <div
                style={{
                  backgroundColor: "white",
                  marginLeft: "-4em",
                  marginTop: "-10.55em",
                  width: "110vw",
                }}
              >
                {" "}
                <Col span={12}>
                  <img src={adminPic} className="admin-pic" alt="Logo" />{" "}
                </Col>
                <Fade duration={1100} delay={200} left cascade>
                  <h1
                    style={{
                      fontSize: "37px",
                      fontWeight: 700,
                      marginTop: "4em",
                      marginLeft: "2em",
                      marginBottom: "0em",
                      paddingTop: "2em",
                      paddingLeft: "2em",
                      textAlign: "left",
                      color: "black",
                    }}
                  >
                    {" "}
                    <img
                      src={restaurant}
                      alt="Logo"
                      style={{
                        width: "1.3em",
                        marginTop: "-0.4em",
                        marginRight: "0.5em",
                      }}
                    />{" "}
                    Restaurant & Bar
                  </h1>{" "}
                </Fade>
                <Row
                  style={{
                    marginLeft: "53%",
                  }}
                >
                  {" "}
                  <Col span={24}>
                    <h1
                      style={{
                        fontSize: "20px",
                        fontWeight: 700,
                        marginTop: "4em",
                        textAlign: "left",

                        color: "black",
                      }}
                    >
                      Sales by Employees
                      <Fade duration={1500} delay={500} bottom>
                        <p
                          style={{
                            textAlign: "left",

                            fontSize: "20px",
                            fontWeight: 400,
                            marginTop: "1em",
                          }}
                        >
                          Track each employee performance and take
                          <br /> informed business decisions.
                          <br />
                        </p>
                      </Fade>
                    </h1>{" "}
                  </Col>
                </Row>
                <Row
                  style={{
                    marginLeft: "53%",
                    textAlign: "right",
                  }}
                >
                  <Col span={24}>
                    <h1
                      style={{
                        fontSize: "20px",
                        fontWeight: 700,
                        marginTop: "1.5em",

                        textAlign: "left",
                        color: "black",
                      }}
                    >
                      Cash Up
                      <Fade duration={1500} delay={500} bottom>
                        <p
                          style={{
                            textAlign: "left",
                            fontSize: "20px",
                            fontWeight: 400,
                            marginTop: "1em",
                          }}
                        >
                          Do daily cash ups to make sure that there is no
                          <br /> money differences.
                          <br />
                        </p>
                      </Fade>
                    </h1>{" "}
                  </Col>{" "}
                </Row>
                <Row
                  style={{
                    marginLeft: "53%",
                    textAlign: "right",
                  }}
                >
                  <Col span={24}>
                    <h1
                      style={{
                        fontSize: "20px",
                        fontWeight: 700,
                        marginTop: "1.5em",

                        textAlign: "left",
                        color: "black",
                      }}
                    >
                      Access Rights
                      <Fade duration={1500} delay={500} bottom>
                        <p
                          style={{
                            textAlign: "left",
                            fontSize: "20px",
                            fontWeight: 400,
                            marginTop: "1em",
                          }}
                        >
                          Manage access to sensitive information and
                          <br /> functions.
                          <br />
                        </p>
                      </Fade>
                    </h1>{" "}
                  </Col>
                </Row>
                <Row
                  style={{
                    marginLeft: "53%",
                    textAlign: "right",
                  }}
                >
                  <Col span={24}>
                    <h1
                      style={{
                        fontSize: "20px",
                        fontWeight: 700,
                        marginTop: "1.5em",

                        textAlign: "left",
                        color: "black",
                      }}
                    >
                      Time and Attendance
                      <Fade duration={1500} delay={500} bottom>
                        <p
                          style={{
                            textAlign: "left",
                            fontSize: "20px",
                            fontWeight: 400,
                            marginTop: "1em",
                            marginBottom: "8em",
                          }}
                        >
                          Employees can clock in/out and system
                          <br /> calculates their total hours to ease wages .
                          <br />
                        </p>
                      </Fade>
                    </h1>{" "}
                  </Col>
                </Row>
              </div>
            </div>
            <img
              src={wave}
              alt="Logo"
              style={{
                marginLeft: "-4em",
                width: "115%",
                minHeight: "30em",
              }}
            />{" "}
            <div
              style={{
                position: "absolute",
                align: "center",
                right: "55%",
                width: "27em",
              }}
            >
              <h1
                style={{
                  fontSize: "20px",
                  marginTop: "-8em",
                  width: "27em",
                  position: "absolute",
                  color: "white",
                }}
              >
                {" "}
                <h1
                  style={{
                    paddingLeft: "0em",
                    fontSize: "30px",
                    fontWeight: 700,
                    marginTop: "-1.5em",
                    left: "41%",
                    color: "white",
                    position: "absolute",
                  }}
                >
                  Get Started with VPOS
                </h1>{" "}
                Drive Sales and boost customer experience with Innovation
              </h1>{" "}
              <div>
                {" "}
                <a href="https://www.devvcsappcloud.com/verkoopnou/signup">
                  <Button
                    style={{
                      marginTop: "-11.7em",
                      position: "absolute",
                    }}
                    className="get-started-buttons"
                    type="primary"
                  >
                    Request a Demo
                  </Button>{" "}
                </a>
              </div>
            </div>
          </div>

          <BottomFooter />
        </Content>
      </React.Fragment>
    );
  }
}

export default SalesScreen;

import React, { Component } from "react";
import { Button, Col, Row, Icon, Drawer } from "antd";
import styled from "styled-components";
import logoGreen from "../static/images/w-delay-logo-green.png";
import Zoom from "react-reveal/Zoom";
import underLine1 from "../static/images/under-line-1.png";
import Fly from "../static/images/fly.png";
import ConceptImg from "../static/images/concept.png";
import DotsLeft from "../static/images/dots-left.png";
import DotsRight from "../static/images/dots-right.png";
import DotsLeftSquare from "../static/images/dots-left-square.png";
import DotsVertical from "../static/images/dots-vertical.png";
import DesignImg from "../static/images/design.png";
import TestingImg from "../static/images/testing.png";
import InteractionImg from "../static/images/interaction.png";
import DevelopmentImg from "../static/images/development.png";
import LaunchImg from "../static/images/launch.png";

import RocketImg from "../static/images/rocket-image.png";
import "./sales-screen.css";
import Fade from "react-reveal/Fade";

import { HashLink as Link } from "react-router-hash-link";
import Header from "../Pages/Header";

const GetQuoteButton = styled(Button)`
  font-family: nunito-regular;
  margin: auto;
  margin-top: 2em;
  margin-bottom: 2em;
  color: white;
  border: 0px solid #19c1b2;
  background-color: #e94347 !important;
  border-radius: 4em;
  font-weight: 100;
  font-size: 20px;
  background-color: white;
  width: 25%;
  min-width: 14em;
  height: 2.8em;
  z-index: 99 !important;
`;

class Services extends Component {
  constructor(props) {
    super(props);
    this.state = {
      placement: "left",
      FormChange: false,
      visible: false,
      open: false,
    };
  }
  onChange = (e) => {
    this.setState({
      placement: e.target.value,
    });
  };
  onChange = (value) => {
    this.setState({
      inputValue: value,
    });
  };
  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };
  render() {
    return (
      <React.Fragment>
        <div
          id="home"
          className="services-div"
          style={{
            top: "0em",
            border: "0px solid #8888882c",
            boxShadow: "none",
            width: "100vw",
            zIndex: "0",
            height: "100vh",
            minHeight: "50em",
            overflowX: "hidden",
            marginLeft: "1em",
          }}
        >
          {" "}
          <Row
            style={{
              marginTop: "2%",
            }}
          >
            {" "}
            <div>
              <Link to="/" class="button-l-2" href="./">
                <svg>
                  <g>
                    <line x2="165.62" y1="31.28" y2="31.28" />
                    <polyline
                      transform=" translate( -61)"
                      points="222.62 25.78 228.12 31.28 222.62 36.78"
                    />
                    <circle
                      cx="285.67"
                      cy="30.94"
                      r="25"
                      transform="rotate(180 224.67 30.94) scale(1, -1) translate(0, -61)"
                    />
                  </g>
                </svg>
              </Link>{" "}
              <font>Back</font>
            </div>
            <Button
              className="burger-menu"
              style={{
                float: "right",
                top: "1.5em",
                right: "0.5em",
                zIndex: 99,
                border: "none",
                backgroundColor: "transparent",
                textShadow: "none",
                boxShadow: "none",
              }}
              type="primary"
              onClick={this.showDrawer}
            >
              <Icon
                style={{ color: "#E94347", fontSize: "28px" }}
                type="menu"
                onClick={this.showDrawer}
              ></Icon>
            </Button>
            <Drawer
              style={{ fontFamily: "nunito" }}
              title="Menu"
              placement={this.state.placement}
              onClose={this.onClose}
              visible={this.state.visible}
            >
              <div style={{}}>
                <Link
                  to="/"
                  style={{
                    color: "white",
                    fontSize: "25px",
                    fontWeight: 500,
                    fontFamily: "nunito",
                  }}
                >
                  Home
                </Link>
              </div>
              <br />
              <Link
                to="/#why-us"
                style={{}}
                style={{
                  top: "3em",
                  color: "white",
                  fontSize: "25px",
                  fontWeight: 700,
                  fontFamily: "nunito",
                }}
              >
                Why Us ?
              </Link>
              <br /> <br />
              <Link
                style={{
                  color: "white",
                  fontSize: "25px",
                  fontWeight: 500,
                  fontFamily: "nunito",
                }}
                to="/#contactus"
              >
                Contact Us
              </Link>{" "}
              <img
                src={RocketImg}
                style={{
                  marginTop: "7em",
                  zIndex: 9,
                  width: "12em",
                  right: 0,
                  left: 20,
                  bottom: 0,
                  position: "absolute",
                }}
              ></img>
            </Drawer>{" "}
            <Link to="/">
              <img
                src={logoGreen}
                alt="Logo"
                className="logo-image-green"
                style={{
                  zIndex: 999,
                  width: "6em",
                  marginTop: "0em",
                  marginLeft: "4%",
                  position: "absolute",
                }}
              />{" "}
            </Link>
            <h1
              className="our-process-header"
              style={{
                margin: "auto",
                marginTop: "0.5em",
                marginBottom: "-0em",
                zIndex: 9,
                width: "16.5em",
                color: "#404040",
                fontFamily: "nunito-regular",
                fontSize: "64px",
                textAlign: "center",
                lineHeight: "49px",
                fontWeight: 100,
              }}
            >
              {" "}
              <Fade duration={1100} delay={200} right cascade>
                How we do
              </Fade>
              <Fade duration={1100} delay={200} right cascade>
                awesomeness
              </Fade>
              <p
                className="our-process-paragraph"
                style={{
                  fontSize: "18px",
                  marginTop: "1em",
                  fontFamily: "nunito-regular",
                  fontWeight: 100,
                  lineHeight: "1em",
                  paddingLeft: "15em",
                  paddingRight: "15em",
                  color: "#5E5E5E",
                }}
              >
                With our well-defined development lifecycle we are able to cater
                for various customer needs
              </p>
            </h1>{" "}
          </Row>
          <div style={{}}>
            {" "}
            <Row
              className="service-row"
              style={{
                margin: "auto",
                marginTop: "4em",
                textAlign: "center",
                maxWidth: "70em",
              }}
            >
              <Col
                sm={24}
                md={12}
                style={{
                  margin: "auto",
                  textAlign: "left",
                }}
              >
                <h1
                  className="left-header1"
                  style={{
                    color: "#404040",
                    fontFamily: "nunito",
                    marginLeft: "0em",
                    marginTop: "1.4em",
                    marginBottom: "-1.2em",
                    fontSize: "23px",
                  }}
                >
                  <Fade duration={1100} delay={280} left cascade>
                    You have an Idea!
                  </Fade>
                </h1>{" "}
                <img
                  className="dots-left-idea"
                  src={DotsLeft}
                  alt="Logo"
                  style={{
                    width: "38em",
                    height: "1.3em",
                    margin: "auto",

                    textAlign: "center",
                  }}
                />
                <p
                  className="left-paragraph1"
                  style={{
                    marginLeft: "0.2em",
                    marginTop: "1em",
                    color: "#373737",
                    fontFamily: "nunito-regular",
                    fontSize: "16px",
                  }}
                >
                  Let's meet over a cup of coffee in order to brainstorm your
                  awesome app idea and requirements
                </p>{" "}
              </Col>{" "}
              <Fade duration={1100} delay={280} right cascade>
                <img
                  className="service-image-small"
                  src={ConceptImg}
                  alt="Logo"
                  style={{
                    width: "8em",
                    margin: "auto",
                    marginTop: "1.5em",
                    marginBottom: "3em",
                    textAlign: "center",
                  }}
                />
              </Fade>
              <Col
                sm={24}
                md={8}
                style={{
                  margin: "auto",
                  textAlign: "center",
                }}
              >
                {" "}
                <Fade duration={1100} delay={280} right cascade>
                  <img
                    className="service-image"
                    src={ConceptImg}
                    alt="Logo"
                    style={{
                      margin: "auto",
                      marginLeft: "6em",
                      textAlign: "center",
                    }}
                  />
                </Fade>
              </Col>
            </Row>{" "}
            <Row
              className="service-row"
              style={{
                margin: "auto",
                textAlign: "center",
                maxWidth: "70em",
                marginTop: "4em",
              }}
            >
              {" "}
              <Col
                sm={24}
                md={8}
                style={{
                  margin: "auto",
                }}
              >
                {" "}
                <Fade duration={1100} delay={330} left cascade>
                  <img
                    className="service-image"
                    src={DesignImg}
                    alt="Logo"
                    style={{
                      margin: "auto",
                      marginRight: "-3em",
                      textAlign: "right",
                    }}
                  />
                </Fade>
              </Col>
              <Col
                sm={24}
                md={12}
                style={{
                  margin: "auto",
                  textAlign: "right",
                  marginTop: "-4em",
                  marginLeft: "-3em",
                }}
              >
                <img
                  className="dots-right"
                  src={DotsRight}
                  alt="Logo"
                  style={{
                    width: "38em",
                    margin: "auto",
                    marginRight: "5em",

                    textAlign: "left",
                  }}
                />{" "}
                <h1
                  className="left-header"
                  style={{
                    color: "#404040",
                    fontFamily: "nunito",
                    marginRight: "-3.3em",
                    marginTop: "-1em",
                    fontSize: "23px",
                  }}
                >
                  {" "}
                  <Fade duration={1100} delay={330} right cascade>
                    UI/UX Design
                  </Fade>
                </h1>{" "}
                <p
                  className="left-paragraph-ui"
                  style={{
                    marginRight: "-4.6em",
                    marginTop: "0.5em",
                    color: "#373737",
                    paddingLeft: "10em",
                    fontFamily: "nunito-regular",
                    fontSize: "16px",
                  }}
                >
                  Now it’s time to give your digital project its own unique look
                  and feel. The designers get creative to make your app stand
                  out
                </p>
              </Col>{" "}
              <Fade duration={1100} delay={1080} left cascade>
                <img
                  className="service-image-small"
                  src={DesignImg}
                  alt="Logo"
                  style={{
                    width: "8em",
                    margin: "auto",
                    marginTop: "1em",
                    marginBottom: "-1em",

                    textAlign: "center",
                  }}
                />
              </Fade>
            </Row>{" "}
            <Row
              className="service-row"
              style={{
                margin: "auto",
                marginTop: "4em",
                textAlign: "center",
                maxWidth: "70em",
              }}
            >
              <Col
                sm={24}
                md={12}
                style={{
                  margin: "auto",
                  textAlign: "left",
                }}
              >
                <h1
                  className="left-header1"
                  style={{
                    color: "#404040",
                    fontFamily: "nunito",
                    marginLeft: "-1.2em",
                    marginBottom: "-1.1em",
                    fontSize: "23px",
                  }}
                >
                  {" "}
                  <Fade duration={1100} delay={370} left cascade>
                    App Development
                  </Fade>
                </h1>{" "}
                <img
                  className="dots-left1"
                  src={DotsLeftSquare}
                  alt="Logo"
                  style={{
                    width: "38em",
                    margin: "auto",
                    marginTop: "-9em",
                    marginLeft: "4em",
                    textAlign: "right",
                  }}
                />
                <p
                  className="left-paragraph1"
                  style={{
                    marginLeft: "-1.6em",
                    marginTop: "1em",
                    color: "#373737",
                    fontFamily: "nunito-regular",
                    fontSize: "16px",
                  }}
                >
                  Now the design is ready, but it’s still a lot of work to turn
                  your idea into a fully-functional product. This is where our
                  deep tech team steps into the game to code all the necessary
                  features
                </p>
              </Col>{" "}
              <Fade duration={1100} delay={280} right cascade>
                <img
                  className="service-image-small"
                  src={TestingImg}
                  alt="Logo"
                  style={{
                    width: "8em",
                    margin: "auto",
                    marginTop: "1em",
                    marginBottom: "-2.5em",

                    textAlign: "center",
                  }}
                />
              </Fade>
              <Col
                sm={24}
                md={8}
                style={{
                  margin: "auto",
                  textAlign: "left",
                }}
              >
                {" "}
                <Fade duration={1100} delay={370} right cascade>
                  <img
                    className="service-image"
                    src={TestingImg}
                    alt="Logo"
                    style={{
                      margin: "auto",
                      marginTop: "-1em",

                      marginLeft: "8em",
                      textAlign: "center",
                    }}
                  />
                </Fade>
              </Col>
            </Row>{" "}
            <Row
              className="service-row"
              style={{
                margin: "auto",
                textAlign: "center",
                maxWidth: "70em",
                marginTop: "4em",
              }}
            >
              {" "}
              <Col
                sm={24}
                md={8}
                style={{
                  margin: "auto",
                  textAlign: "right",
                }}
              >
                {" "}
                <Fade duration={1100} delay={200} left cascade>
                  <img
                    className="service-image"
                    src={LaunchImg}
                    alt="Logo"
                    style={{
                      margin: "auto",
                      marginTop: "-2.5em",
                      marginRight: "4em",
                      textAlign: "center",
                    }}
                  />
                </Fade>
              </Col>
              <Col
                sm={24}
                md={12}
                style={{
                  margin: "auto",
                  textAlign: "right",
                }}
              >
                <h1
                  className="left-header-quality"
                  style={{
                    color: "#404040",
                    fontFamily: "nunito",
                    marginRight: "-3.6em",
                    marginTop: "1em",
                    marginBottom: "-11.2em",
                    fontSize: "23px",
                  }}
                >
                  {" "}
                  <Fade duration={1100} delay={200} right cascade>
                    Quality Assurance
                  </Fade>
                </h1>{" "}
                <img
                  className="dots-right"
                  src={DotsRight}
                  alt="Logo"
                  style={{
                    width: "38em",
                    margin: "auto",
                    marginLeft: "-7em",
                    marginTop: "8em",
                    textAlign: "center",
                  }}
                />
                <p
                  className="left-paragraph-quality"
                  style={{
                    marginRight: "-5em",
                    paddingLeft: "10em",
                    marginTop: "1em",
                    color: "#373737",
                    fontFamily: "nunito-regular",
                    fontSize: "16px",
                  }}
                >
                  Next step is to ensure that we have tested all scenarios and
                  that we are ready to launch your idea
                </p>
              </Col>{" "}
              <Fade duration={1100} delay={280} left cascade>
                <img
                  className="service-image-small"
                  src={LaunchImg}
                  alt="Logo"
                  style={{
                    width: "8em",
                    margin: "auto",
                    marginTop: "1em",
                    marginBottom: "-4em",

                    textAlign: "center",
                  }}
                />
              </Fade>
            </Row>{" "}
            <Row
              className="service-row"
              style={{
                margin: "auto",
                marginTop: "4em",
                textAlign: "center",
                maxWidth: "70em",
              }}
            >
              <Col
                sm={24}
                md={12}
                style={{
                  margin: "auto",
                  textAlign: "left",
                  marginLeft: "1em",
                }}
              >
                <h1
                  className="left-header-launch"
                  style={{
                    color: "#404040",
                    fontFamily: "nunito",
                    marginLeft: "-1.8em",
                    marginTop: "2em",
                    marginBottom: "-6.3em",
                    fontSize: "23px",
                  }}
                >
                  {" "}
                  <Fade duration={1100} delay={200} left cascade>
                    App Store Launch
                  </Fade>
                </h1>{" "}
                <img
                  className="dots-left1"
                  src={DotsLeftSquare}
                  alt="Logo"
                  style={{
                    width: "38em",
                    margin: "auto",
                    marginLeft: "3em",

                    textAlign: "center",
                  }}
                />
                <p
                  className="left-paragraph2"
                  style={{
                    marginLeft: "-2.5em",
                    marginTop: "1em",
                    color: "#373737",
                    fontFamily: "nunito-regular",
                    fontSize: "16px",
                  }}
                >
                  Your new app is ready for download on the App store or Google
                  Play store
                </p>
              </Col>{" "}
              <Fade duration={1100} delay={280} right cascade>
                <img
                  className="service-image-small"
                  src={DevelopmentImg}
                  alt="Logo"
                  style={{
                    width: "8em",
                    margin: "auto",
                    marginTop: "1em",
                    marginBottom: "-7em",

                    textAlign: "center",
                  }}
                />
              </Fade>
              <Col
                sm={24}
                md={8}
                style={{
                  margin: "auto",
                  textAlign: "left",
                }}
              >
                {" "}
                <Fade duration={1100} delay={200} right cascade>
                  <img
                    className="service-image"
                    src={DevelopmentImg}
                    alt="Logo"
                    style={{
                      margin: "auto",
                      marginLeft: "5em",
                      textAlign: "center",
                    }}
                  />
                </Fade>
              </Col>
            </Row>{" "}
            <Row
              className="service-row"
              style={{
                margin: "auto",
                textAlign: "center",
                maxWidth: "70em",
                marginTop: "0em",
              }}
            >
              {" "}
              <Col
                sm={24}
                md={8}
                style={{
                  margin: "auto",
                  textAlign: "right",
                }}
              >
                {" "}
                <Fade duration={1100} delay={200} left cascade>
                  <img
                    className="service-image"
                    src={InteractionImg}
                    alt="Logo"
                    style={{
                      width: "12.5em",
                      margin: "auto",
                      marginRight: "2em",
                      textAlign: "right",
                    }}
                  />
                </Fade>
              </Col>
              <Col
                sm={24}
                md={12}
                style={{
                  margin: "auto",
                  textAlign: "right",
                  marginTop: "6em",
                  marginLeft: "-5em",
                }}
              >
                <h1
                  className="left-header"
                  style={{
                    color: "#404040",
                    fontFamily: "nunito",
                    marginRight: "-5em",
                    marginBottom: "-6.3em",
                    fontSize: "23px",
                  }}
                >
                  {" "}
                  <Fade duration={1100} delay={200} right cascade>
                    Maintenance
                  </Fade>
                </h1>{" "}
                <img
                  className="dots-right"
                  src={DotsRight}
                  alt="Logo"
                  style={{
                    width: "38em",
                    margin: "auto",
                    marginLeft: "3em",

                    textAlign: "center",
                  }}
                />
                <p
                  className="left-paragraph-maintenance"
                  style={{
                    marginRight: "-7.2em",
                    marginTop: "1em",
                    color: "#373737",
                    paddingLeft: "10em",
                    fontFamily: "nunito-regular",
                    fontSize: "16px",
                  }}
                >
                  We offer excellent maintenance packages at affordable prices
                  to ensure your product is up to date
                </p>
              </Col>{" "}
              <Fade duration={1100} delay={280} left cascade>
                <img
                  className="service-image-small"
                  src={InteractionImg}
                  alt="Logo"
                  style={{
                    width: "8em",
                    margin: "auto",
                    marginTop: "1em",
                    marginBottom: "1em",

                    textAlign: "center",
                  }}
                />
              </Fade>
            </Row>{" "}
          </div>{" "}
          <Row
            className="services-button"
            style={{
              textAlign: "center",
              marginTop: "3em",
            }}
          >
            <Link to="./#contact-us">
              <GetQuoteButton>Start Your Project</GetQuoteButton>
            </Link>{" "}
            <Row
              style={{
                position: "absolute",
                textAlign: "center",
                bottom: "-2em",
                right: 0,
                left: 0,
                color: "grey",
              }}
            >
              <p style={{ fontSize: "13px" }}>
                © 2020 Venturepoint - All rights reserved
              </p>
            </Row>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}

export default Services;

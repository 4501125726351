import React, { PureComponent } from "react";
import { Layout, Button, Row, Col } from "antd";
import logoWhite from "../static/images/v-logo-white.svg";
import { Link } from "react-router-dom";
import logoGreen from "../static/images/w-delay-logo-green.png";
import Headroom from "react-headroom";
import $ from "jquery";
$(function () {
  $(window).on("scroll", function () {
    if ($(window).scrollTop() > 50) {
      $(".header").addClass("active");
    } else {
      //remove the background property so it comes transparent again (defined in your css)
      $(".header").removeClass("active");
    }
  });
});

const Header = () => {
  return (
    <Headroom style={{ backgroundColor: "#132440", zIndex: 999 }}>
      <Row
        className="header"
        style={{
          margin: "auto",
          paddingTop: "2.9em",
          paddingBottom: "1em",

          zIndex: 999,
          width: "120vw",
          maxWidth: "92em",
          backgroundColor: "#0b7b9444",
        }}
      >
        <div style={{}}>
          <Col sm={0} md={1}></Col>
          <Col sm={0} md={10}>
            <img
              src={logoWhite}
              alt="Logo"
              className="logo-image"
              style={{ marginTop: "-0.5em", width: "6.4em", boxShadow: "none" }}
            />
          </Col>
          <div className="header-links">
            <Col sm={0} md={3} style={{ width: "8em" }}>
              {" "}
              <a href="#home">
                <a
                  className="header-links-style"
                  style={{
                    fontFamily: "nunito",
                    color: "white",
                    fontSize: "1.1rem",
                    fontWeight: 500,
                  }}
                >
                  Home
                </a>{" "}
              </a>{" "}
            </Col>
            <Col sm={0} md={3} style={{ width: "9em" }}>
              <a href="#what-we-do">
                <a
                  className="header-links-style"
                  style={{
                    fontFamily: "nunito",
                    whiteSpace: "nowrap",
                    color: "white",
                    fontSize: "1.1rem",
                    fontWeight: 500,
                  }}
                >
                  Services
                </a>
              </a>
            </Col>
            <Col sm={0} md={3} style={{ width: "9em" }}>
              <a
                className="header-links-style"
                style={{
                  fontFamily: "nunito",
                  color: "white",
                  fontSize: "1.1rem",
                  fontWeight: 500,
                }}
                href="#why-us"
              >
                Why us ?
              </a>{" "}
            </Col>
            <Col sm={0} md={3} style={{ width: "9em" }}>
              <a
                className="header-links-style"
                style={{
                  fontFamily: "nunito",
                  color: "white",
                  fontSize: "1.1rem",

                  fontWeight: 500,
                }}
                href="#contact-us"
              >
                Contact Us
              </a>{" "}
            </Col>
            <Col sm={0} md={3}>
              <a
                href="#contact-us"
                className="header-links-button-style"
                style={{
                  fontFamily: "nunito",
                  height: "2.1em",
                  width: "8em",
                  border: "none",
                  fontSize: "1.1rem",
                  fontWeight: 500,
                  borderRadius: "3em",
                  backgroundColor: "#E94347",
                  color: "white",
                  bottom: "0.35em",
                  padding: "0.5em",
                  paddingLeft: "1.5em",
                  paddingRight: "1.5em",
                  textShadow: "none",
                }}
                type="primary"
              >
                Let's Start
              </a>
            </Col>
          </div>
        </div>
      </Row>
    </Headroom>
  );
};

export default Header;
